import { format } from 'date-fns'

export default class DateUtils{
    /**
     * current hours : minutes
     * @return {string}
     */
    nowTimeHHmm(){
        return this.timeHHmm(new Date());
    }

    /**
     * To get something like 23:59
     * @param {Date} date
     * @return {string}
     */
    timeHHmm(date){
        return format(date, 'HH:mm')
    }
    /**
     * To get something like 31/12/1999 23:59:59
     * @param {Date} date
     * @return {string}
     */
    humanFullDate(date){
        return format(date, 'dd/MM/yyy HH:mm:ss')
    }
}
<template>
  <v-navigation-drawer
      app
      v-model="$ui.mainNavOpen"
      disable-resize-watcher
      temporary
      right
      width="330"
      style="z-index: var(--z-nav-drawer)"
  >
    <v-list>
      <v-list-item :to="{name:'home'}">
        <io-text-regular>
          {{$t("pages.home.label")}}
        </io-text-regular>
      </v-list-item>
      <v-list-item>
        <io-text-regular>
          {{$t("pages.user-preferences.label")}}
        </io-text-regular>
      </v-list-item>
      <v-list-item>
        <io-text-regular>
          {{$t("pages.how-it-works.label")}}
        </io-text-regular>
      </v-list-item>
      <v-list-item>
        <io-text-regular>
          {{$t("pages.about.label")}}
        </io-text-regular>
      </v-list-item>
      <v-list-item>
        <io-text-regular>
          {{$t("pages.pro-solutions.label")}}
        </io-text-regular>
      </v-list-item>
      <v-list-item>
        <io-text-regular>
          {{$t("pages.legal-usage-conditions.label")}}
        </io-text-regular>
      </v-list-item>

      <v-subheader>Language</v-subheader>
      <v-list-item
          v-for="lang of $locales.locales"
          :key="lang.code"
          @click="$locales.currentLocale=lang;$ui.mainNavOpen=false;"
      >
        <v-list-item-avatar>
          <io-dot v-if="lang.isCurrent"
                  color="var(--color-green)"
          />
        </v-list-item-avatar>
        <v-list-item-content class="text-capitalize">
          <io-text-regular>{{lang.label}}</io-text-regular>
        </v-list-item-content>
        <v-list-item-action>
        </v-list-item-action>
      </v-list-item>
    </v-list>

      <v-divider></v-divider>

      <v-subheader>Dev Only</v-subheader>
      <v-list-item to="my-name-is">
        <io-text-regular>My name is...</io-text-regular>
      </v-list-item>
      <v-list-item to="style-guide">
        <io-text-regular>Style Guide</io-text-regular>
      </v-list-item>
      <v-list-item
          v-if="$ui.isElectron"
          @click="$ui.openDevConsole(true)">
        Console
      </v-list-item>


    <!-- version / app updater -->
    <template slot="append">
      <v-card>
        <v-container>
          <release-updater/>
        </v-container>
      </v-card>
    </template>
  </v-navigation-drawer>
</template>

<script>
import ReleaseUpdater from "@/components/release-updater/release-updater";
import IoTextRegular from "@/components/atoms/io-text-regular";
import IoDot from "@/components/atoms/io-dot";
export default {
  name: "io-app-drawer",
  components: {IoDot, IoTextRegular, ReleaseUpdater}
}
</script>

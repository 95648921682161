<template>
<h1 class="io-text-title" :style="{color:textColor}">
  <slot></slot>
</h1>
</template>

<script>
import IoTextMixin from "@/components/atoms/IoTextMixin";
export default {
  name: "io-text-title",
  mixins:[IoTextMixin],
  props:{
    color:{
      type:String,
      default:"title"
    }
  }

}
</script>

<template>
  <div>
    <sg-divider >io-room-container</sg-divider>
    <io-room-container
        user-name="Paul"
        partner-name="Ringo"
    >
      Content here
    </io-room-container>

    <io-spacing large/>

    <io-room-container
        room-name="ABCDEFGHI"
        connected
        user-name="Paul"
        partner-name="Ringo"
    >
      Content here
    </io-room-container>



    <sg-divider >io-dialog-legal</sg-divider>
    <io-dialog-legal
        :visible="showLegal"
        @accept="showLegal=false"
        @download="test('vous voulez télécharger les CGU')"
    />
    <io-btn
        @click.native="showLegal=true;">
      ouvrir la fenêtre
    </io-btn>

    <sg-divider>io-create-connection</sg-divider>
    <io-create-connection
        @create-room="$ui.alert('Je veux créer une connexion')"
        @connect-to-room="$ui.alert(`Je veux me connecter à ${$event}`)"
    />

    <sg-divider>io-user-name-input</sg-divider>
    <v-row>
      <v-col>
        <io-user-name-input
            @create-room="$ui.alert('Je veux créer une connexion')"
            @connect-to-room="$ui.alert(`Je veux me connecter à ${$event}`)"
        />
      </v-col>
      <v-col>
        <io-user-name-input
            disabled
            @create-room="$ui.alert('Je veux créer une connexion')"
            @connect-to-room="$ui.alert(`Je veux me connecter à ${$event}`)"
        />
      </v-col>
    </v-row>



  </div>

</template>

<script>
import SgDivider from "@/style-guide/sg-divider";
import IoDialogLegal from "@/components/organisms/io-dialog-legal";
import IoBtn from "@/components/atoms/io-btn";
import IoCreateConnection from "@/components/organisms/io-create-connection";
import IoUserNameInput from "@/components/organisms/io-user-name-input";
import IoRoomContainer from "@/components/organisms/io-room-container";
import IoSpacing from "@/components/atoms/io-spacing";
export default {
  name: "sg-organisms",
  components: {IoSpacing, IoRoomContainer, IoUserNameInput, IoCreateConnection, IoBtn, IoDialogLegal, SgDivider},
  data(){
    return{
      showLegal:false
    }
  },
  methods:{
    test(msg){
      alert(msg);
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="io-file-progress"
       :class="{
          'upload':isUpload,
          'download':isDownload,
       }">
    <div class="bar io-elevation-overboard"
         :style="{'background':`linear-gradient(90deg,${colorMime} 0%,${colorSize} 100%`}">
      <div class="move"
           :style="{'background':`linear-gradient(90deg,${colorIdOpacity} 0%, ${colorId} 30%, ${colorId} 70%,${colorIdOpacity} 100%`}"/>
    </div>

    <io-text-log
        class="progress-text">
      {{percentageInt}}%
    </io-text-log>

  </div>

</template>

<script>
import FileAltMixin from "@/components/file-items/FileAltMixin";
import IoTextLog from "@/components/atoms/io-text-log";

export default {
  name: "io-file-progress",
  components: {IoTextLog},
  mixins:[FileAltMixin],
  props:{
    percent:{
      type:Number
    }
  },
  computed:{
    percentageFloat(){
      return this.percent;
    },
    percentageInt(){
      return Math.floor(this.percent);
    }
  }
}
</script>

<style lang="less">
.io-file-progress{
  min-width: 10px;
  transition: width 0.2s linear;
  .bar{
    height: 6px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &:after{
      display: block;
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      content:"";
      box-shadow:
          inset 3px  3px 6px rgba(0, 0, 0, 0.4)
    ;
      z-index: 10;
    }
  }

  .move{
    height: 6px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    animation-name:"progress";
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    mix-blend-mode: normal;
  }
  .progress-text{
    margin-top: 13px;
  }
  &.upload{
    .move{
      animation-direction: normal;
    }
    .progress-text{
      text-align: right;

    }
  }
  &.download{
    .move{
      animation-direction: reverse;
    }
  }
  @keyframes progress{
    0%{
      transform: translateX(-100%);
    }
    100%{
      transform: translateX(100%);
    }
  }
}
</style>
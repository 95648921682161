<template>
<div class="io-user-name-input"
:class="{inactive:!active}"
>
  <io-icon-elevate>
    <icon-user
        size="40px"
        color="var(--color-velvet)" class=""
        v-if="!loading"
    />
    <v-progress-circular
        v-if="loading"
        indeterminate
        color="var(--color-green)"/>

  </io-icon-elevate>

  <io-text-field-submit
      v-if="active && !loading"
      class="flex-grow-1"
      color="velvet"
      v-model="inputVal"
      :placeholder="placeholders"
      :active="active"
      @submit="$emit('submit')"
  />
  <io-text-label
    v-else-if="!loading">{{inputVal}}
  </io-text-label>

  <io-text-label v-if="loading">
    <io-motion-text-slide
        class=" ml-5"
        :items="[
                  $t('connection.wait'),
                  '...'
              ]"
    />
  </io-text-label>

</div>
</template>

<script>
import IoTextFieldSubmit from "@/components/molecules/io-text-field-submit";
import IoIconElevate from "@/components/atoms/io-icon-elevate";
import IconUser from "@/components/atoms/icons/icon-user";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoMotionTextSlide from "@/components/atoms/motion-text/io-motion-text-slide";

export default {
  name: "io-user-name-input",
  components: {IoMotionTextSlide, IoTextLabel, IconUser, IoIconElevate, IoTextFieldSubmit},
  props:{
    value:{
      type:String,
    },
    active:{
      type:Boolean,
      default:true
    },
    loading:{
      type:Boolean,
      default:false
    },
  },
  data(){
    return{
      placeholders:[],
      loop:null,
    }
  },
  computed: {
    locale(){
      return this.$locales.currentLocaleCode
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods:{
    generatePlaceholders(){
      this.placeholders=[];
      while (this.placeholders.length<20){
        this.placeholders.push(this.$t("nickname.placeholder"));
        this.placeholders.push(this.$services.user.getRandomName()+" ?");
      }
    }
  },
  mounted() {
    this.generatePlaceholders();
  },
  watch:{
    locale(){
      let me=this;
      setTimeout(function(){
        me.generatePlaceholders();
      },100)

    }
  }
}
</script>

<style lang="less">
.io-user-name-input{
  background-color: #fff;
  box-shadow: var(--io-elevation-plane);
  max-width: 430px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  //justify-content: space-between;

  padding: 35px 40px;
  transition: all 0.2s;

  &.inactive{
    padding: 5px 20px 5px 5px;
    .io-icon-elevate{
      box-shadow: none;
    }
  }

}
</style>

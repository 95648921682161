import FileUtils from "@/utils/FileUtils";
import ColorsUtils from "@/utils/ColorsUtils";
import DateUtils from "@/utils/DateUtils";

export default class Utils{
    constructor() {
        /**
         * Common methods for files and directories
         * @type {FileUtils}
         */
        this.files=new FileUtils();
        /**
         * Colors manipulation and randomization
         * @type {ColorsUtils}
         */
        this.colors=new ColorsUtils();
        /**
         * Common date / time methods
         * @type {DateUtils}
         */
        this.date=new DateUtils();
    }

    /**
     *
     * @param {number} inputValue
     * @param {number} inputMax
     * @param {number} outputMax
     * @param {number} inputMin
     * @param {number} outputMin
     * @returns {number}
     */
    ratio(inputValue, inputMax, outputMax, inputMin=.0, outputMin=.0){
        let product = (inputValue - inputMin) / (inputMax - inputMin);
        return ((outputMax - outputMin) * product) + outputMin;
    }

}
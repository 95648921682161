<template>
<div>
  <sg-divider>io-tab-label-room</sg-divider>
  <io-tab-label-room
      room-name="123456789"
  />
  <io-spacing/>
  <io-tab-label-room
      room-name="ABCDEFGHI"
      connected
  />


  <sg-divider>io-text-field-submit <code>{{textFieldValue}}</code> </sg-divider>
  <io-card>
    <v-row>
      <v-col sm="6">
        <io-text-field-submit
            @submit="test('@submit '+textFieldValue)"
            placeholder="hello world"
            v-model="textFieldValue"
        />
      </v-col>
      <v-col sm="6">
        <io-text-field-submit
            @submit="test('@submit '+textFieldValue)"
            :placeholder="['Rolling Stones','The Beatles','Velvet Underground','Pink Floys','The Doors','Led Zeppelin']"
            v-model="textFieldValue"
            color="velvet"
        />
      </v-col>
    </v-row>
  </io-card>

  <sg-divider>io-chip-player </sg-divider>

  <v-row>
    <v-col sm="6" class="text-right">
      <io-chip-player user-name="Marie"/>
    </v-col>
    <v-col sm="6">
      <io-chip-player user-name="Paul" is-partner />
    </v-col>

    <v-col sm="6" class="text-right">
      <io-chip-player user-name="Marie" is-connected/>
    </v-col>
    <v-col sm="6">
      <io-chip-player user-name="Paul" is-partner is-connected/>
    </v-col>

  </v-row>




</div>
</template>

<script>
import SgDivider from "@/style-guide/sg-divider";
import IoTextFieldSubmit from "@/components/molecules/io-text-field-submit";
import IoCard from "@/components/atoms/io-card";
import IoChipPlayer from "@/components/molecules/io-chip-player";
import IoTabLabelRoom from "@/components/molecules/io-tab-label-room";
import IoSpacing from "@/components/atoms/io-spacing";
export default {
  name: "sg-molecules",
  components: {IoSpacing, IoTabLabelRoom, IoChipPlayer, IoCard, IoTextFieldSubmit, SgDivider},
  data(){
    return{
      textFieldValue:''
    }
  },
  methods:{
    test(message){
      alert(message);
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <sg-divider>io-card</sg-divider>

    <div class="d-flex flex-column justify-center align-center">
      <io-card>
        default slot
        <template slot="actions">
          slot:actions
        </template>
      </io-card>

      <io-spacing/>

      <io-card>
        <io-text-title>Ceci est une io-card classique</io-text-title>
        <io-spacing/>
        <io-text-label class="shrink">
          On peut y placer des trucs directement dedans.
          On peut aussi y placer des boutons dans le slot:actions par exemple.
        </io-text-label>
        <template slot="actions">
          <v-row class="text-center ">
            <v-col class="text-md-left">
              <io-btn-text>On est dans slot:actions</io-btn-text>
            </v-col>
            <v-spacer class="d-none d-sm-block"/>
            <v-col class="text-md-right">
              <io-btn @click="alert('hop')">Gentil io-btn</io-btn>
            </v-col>
          </v-row>
        </template>
      </io-card>

      <io-spacing/>

      <io-card class="text-center">
        <io-text-code-lg>X2B12</io-text-code-lg>
        <io-btn-text small>J'ai transmis le code</io-btn-text>
        <template slot="actions">
          <io-btn small>Envoyer le code par mail</io-btn>
        </template>
      </io-card>
    </div>
  </div>

</template>

<script>
import IoCard from "@/components/atoms/io-card";
import IoSpacing from "@/components/atoms/io-spacing";
import IoTextTitle from "@/components/atoms/io-text-title";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoBtn from "@/components/atoms/io-btn";
import IoBtnText from "@/components/atoms/io-btn-text";
import IoTextCodeLg from "@/components/atoms/io-text-code-lg";
import SgDivider from "@/style-guide/sg-divider";
export default {
  name: "sg-card",
  components: {SgDivider, IoTextCodeLg, IoBtnText, IoBtn, IoTextLabel, IoTextTitle, IoSpacing, IoCard}
}
</script>

<style scoped>

</style>
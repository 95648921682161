<template>
<div class="io-motion-text-encoding">{{randomText}}</div>
</template>

<script>
const random= require('lodash/random');
export default {
  name: "io-motion-text-encoding",
  components:{},
  props:{
    /**
     * The final text to display, if null will loop
     * @type {string}
     */
    text:{
      type:String,
    },
    /**
     * Possible characters for random display
     * @type {string}
     */
    possibleChars:{
      type:String,
      default:"abcdefghijklmnopqrstuvwxyz_/*!?#0123456789"
    },
    /**
     * minimum random signs to display
     * @type {string|Number}
     */
    minLength:{
      type:[String,Number],
      default:"10"
    },
    /**
     * maximum random signs to display
     * @type {string|Number}
     */
    maxLength:{
      type:[String,Number],
      default:"10"
    }
  },
  data(){
    return{
      interval:null,
      seed:1,
      stopping:false,
      final:null
    }
  },
  mounted() {
    this.onChangeText();
  },
  watch:{
    text(){
      this.onChangeText();
    }
  },
  methods:{
    onChangeText(){
      this.play()
      if(this.text){
        this.stopping=true;
        this.final=[];
        while(this.final.length<this.text.length){
          this.final.push(false);
        }
      }
    },
    play(){
      this.stop();
      let me=this;
      this.interval=setInterval(
          function(){
            me.seed=Math.random()/100000;
            if(me.stopping){
              let finished=true;
              for(let i=0 ;i < me.final.length;i++){
                if(me.final[i] !== true){
                  finished=false;
                  break;
                }
              }
              if(!finished && random(0,10)>5){
                let todo=false;
                while(todo===false){
                  let pos=random(0,me.final.length);
                  if(me.final[pos]===false){
                    todo=pos;
                  }
                }
                me.final[todo] = true;
              }
            }
            if(me.finished){
              me.stop();
            }

          },
          60
      )
    },
    /**
     * stop set interval
     * @return {string}
     */
    stop(){
      if(this.interval){
        clearInterval(this.interval)
      }
    },

    /**
     * get a single random char
     * @return {string}
     */
    randomSign(){
      let r=(this.seed+Math.random());
      return this.chars[Math.floor(r * this.chars.length)];
    },
    /**
     * How many chars to display
     * @return {number|*}
     */
    howMany(){
      if(this.text){
        return this.text.length;
      }
      return random(Number(this.minLength),Number(this.maxLength),false);
    },

  },
  computed:{
    /**
     * Possibles chars as array
     * @return {string[]}
     */
    chars(){
      return this.possibleChars.split("");
    },
    /**
     * Generates random text according context
     * @return {string}
     */
    randomText(){
      let ar=[];
      let length=this.howMany();
      if(this.stopping){
        for(let i=0 ;i < this.final.length;i++){
          let place=this.final[i];
          if(!place){
            ar.push(this.randomSign());
          }else{
            ar.push(this.text.charAt(i));
          }
        }

      }else{
        while(ar.length<length){
          ar.push(this.randomSign())
        }
      }

      return ar.join("");
    }
  }

}
</script>


export default class MessageService {
    constructor(peer, partnerName = "Partner") {
        /**
         * WebRTC connection service
         * @type {SimplePeer}
         */
        this.peer = peer;
        /**
         * Name of the exchange partner
         * @type {string}
         */
        this.partnerName = partnerName;
        /**
         * List of messages exchanged between partners
         * @type {Message[]}
         */
        this.messages = [];

        this.peer.on('data', data => {
            const json = JSON.parse(data);
            if (json.type === "message") {
                this.messages.push(json);
            }
        });
    }

    get userName() {
        return window.$services.user.userName;
    }

    /**
     * Send a text message to the partner
     * @param {string} message
     */
    sendMessage(message) {
        const data = new Message(this.userName, message);
        this.messages.push(data);
        this.peer.send(JSON.stringify(data));
    }
}

export class Message {
    constructor(sender, message) {
        this.type = "message";
        this.timestamp = Date.now();
        this.user = sender;
        this.content = message;
    }
}

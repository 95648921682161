import * as rug from 'random-username-generator';
rug.setSeperator(' ');
export default class UserNameService {

    constructor() {
        if (window.$services && window.$services.user) {
            alert('Duplicate UserNameService');
            console.warn('Duplicate UserNameService');
            return false;
        }
        /**
         * The current user name (may not be valid)
         * @type {string}
         * @private
         */
        this._userName="";
        this._checkUserNameFromLS();
        let me=this;
        //check for LS in other browser windows
        setInterval(function(){
            me._checkUserNameFromLS();
        },10000);
    }

    /**
     * Get the username from local storage
     * @private
     */
    _checkUserNameFromLS(){
        this._userName=localStorage.getItem("userName");
    }

    get userName() {
        return this._userName;
    }

    set userName(name) {
        this._userName = name;
        localStorage.setItem("userName", name);
    }
    /**
     * Return error (if there is...)
     * @return {string|null}
     */
    get error(){
        if(!this.userName || this.userName === "" || this.userName ===  undefined){
            return "nickname.error-mandatory-name"
        }
        if(this.userName.length<3){
            return "nickname.error-name-short"
        }
        return null;
    }

    /**
     * Is the username valid ?
     * @return {boolean}
     */
    get isValid(){
        return this.error===null;
    }

    /**
     * Returns a random name
     * (could be static or utility method)
     * @return {string}
     */
    getRandomName(){
        return rug.generate();
    }
}

<template>
  <io-card class="io-file-menu-card">
    <template slot="header">
      <io-text-regular color="#060E23">{{name}}</io-text-regular>
    </template>
    <div class="line-menu"
         v-if="file.possibleAction.PAUSE"
         @click="$ui.fileTransferService.pauseFileTransfer(file)">
      <v-icon>io-ico-pause</v-icon>
      <io-text-label>{{$t("file-transfer-actions.pause")}}</io-text-label>
    </div>
    <div class="line-menu"
         v-if="file.possibleAction.RESUME"
         @click="$ui.fileTransferService.resumeFileTransfer(file)">
      <v-icon>io-ico-delete</v-icon>
      <io-text-label>{{$t("file-transfer-actions.resume")}}</io-text-label>
    </div>
    <div class="line-menu"
         v-if="file.possibleAction.CANCEL"
         @click="$ui.fileTransferService.cancelFileTransfer(file)">
      <v-icon size="25px">io-ico-delete</v-icon>
      <io-text-label>{{$t("file-transfer-actions.cancel")}}</io-text-label>
    </div>
    <div class="line-menu"
         v-if="file.possibleAction.DOWNLOAD"
         @click="$ui.fileTransferService.downloadFile(file)">
      <v-icon size="25px">io-ico-download</v-icon>
      <io-text-label>{{$t("file-transfer-actions.save")}}</io-text-label>
    </div>
    <div class="logs">
      <io-log-line
          v-for="(log,i) of logs"
           :key="'log '+i"
           :date="log.date"
          :message="log.message"
      />
    </div>
    <div class="dots">
      <io-dot size="6px" :color="colorId"></io-dot>
      <io-dot size="6px" :color="colorMime"></io-dot>
      <io-dot size="6px" :color="colorSize"></io-dot>
    </div>

  </io-card>
</template>

<script>
import FileAltMixin from "@/components/file-items/FileAltMixin";
import IoTextRegular from "@/components/atoms/io-text-regular";
import IoCard from "@/components/atoms/io-card";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoDot from "@/components/atoms/io-dot";
import IoLogLine from "@/components/atoms/io-log-line";

export default {
  name: "io-file-menu-card",
  mixins:[FileAltMixin],
  components: {IoLogLine, IoDot, IoTextLabel, IoTextRegular, IoCard},
  computed:{
    logs(){
      return this.file.logs.concat().reverse();
    }
  }
}
</script>

<style lang="less">
.io-file-menu-card{
  box-shadow: none;
  border-radius: 20px;
  width: 330px;
  position: relative;
  .header{
    padding: 20px 30px;
    background-color: var(--color-grey-ultra-light);
  }
  .content{
    padding: 0;
  }
  .line-menu{
    cursor:pointer;
    &:hover{
      background-color: #f0f0f0;
    }
    --color:#ECEEF5;
    padding-left: 30px;
    height: 55px;
    border-bottom: 1px solid #ECEEF5;
    display: flex;
    align-items: center;
    .v-icon{
      margin-right: 20px;
      color: #A3AEC9;
    }
    .io-text-label{
      color: #A3AEC9;
      text-transform: uppercase;
      font-size: 10px !important;
    }
  }
  .logs{
    padding: 20px 30px;
    max-height: 300px;
    overflow-y: auto;
    .io-log-line{
      margin: 5px 0;
    }
  }
  .dots{
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    align-items: center;
    >*{
      margin-left: 5px;
    }
  }
}
</style>
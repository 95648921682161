<template>
<div class="io-card" >
  <div class="header" v-if="this.$slots.header">
  <slot name="header"></slot>
  </div>
  <div class="content">
    <slot></slot>
  </div>
  <div class="d-flex actions" v-if="this.$slots.actions">
    <slot name="actions"></slot>
  </div>
</div>
</template>

<script>
export default {
  name: "io-card"
}
</script>

<style lang="less">
.io-card{
  background-color: #fff;
  border-radius: 16px;
  box-shadow:  var(--io-elevation-plane);
  overflow: hidden;
  @media(min-width: @screen-sm){
    border-radius: 26px;
  }
  @media(min-width: @screen-md){
    border-radius: 46px;
  }
  .header,.actions,.content{
    padding: 30px 20px;
    @media(min-width: @screen-sm){
      padding: 40px 60px;
    }
    @media(min-width: @screen-md){
      padding: 60px 80px;
    }
  }
  .actions{
    padding-top: 15px;
    padding-bottom: 30px;
  }






}
</style>
const randomColor = require('randomcolor');

export default class ColorsUtils{

    /**
     * blend two hex colors together by an amount
     * @param colorA
     * @param colorB
     * @param amount
     * @return {string}
     */
    blendColors(colorA, colorB, amount) {
        const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
        const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
        const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0');
        const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0');
        const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0');
        return '#' + r + g + b;
    }

    /**
     * Random seed color
     * @param hash
     * @return {*}
     */
    hashColor(hash){
        return randomColor({
            luminosity: 'bright',
            hue: 'random',
            'seed':hash
        })
    }

    /**
     * Color from mime type / extension
     * @param {string} mime
     * @param {string} extension
     * @return {string} Hexa color
     */
    mimeColor(mime="",extension=""){
        let fam=window.$utils.files.fileFamily(mime,extension);
        let colors={
            image:"#f3cb02",
            video:"#cbff50",
            audio:"#b46c16",
            doc:"#50adff",
            excel:"#02fd8d",
            archive:"#ee56ff",
            code:"#ff0000",
            exe:"#660899",
            "":"monochrome"
        }
        let hue=colors[fam];
        let lum="bright";
        let seed=mime+extension;
        return randomColor({
            luminosity: lum,
            hue: hue,
            'seed':seed
        })
    }

    /**
     * Color from bytes
     * @param bytes
     * @return {string}
     */
    fileSizeColor(bytes){
        let max=1024*1024*1024;
        bytes=Math.min(bytes,max);
        switch (true){
            case bytes<10*1024*1024: // < 10 MO
                return this.blendColors("#90ff00","#FFFF88",bytes/(10*1024*1024));
            case bytes<200*1024*1024: // < 200 MO
                return this.blendColors("#FFFF88","#ffaa00",bytes/(200*1024*1024));
            default:
                return this.blendColors("#ffaa00","#b2001f",bytes/max);
        }
    }

}
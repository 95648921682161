<template>
  <div>
    <v-container>
      <section class="mb-10">
        <h1 class="text-h1 mb-10">Les fichiers</h1>
        <sg-files/>
      </section>
      <section class="mb-10">
        <h1 class="text-h1 mb-10">Organismes</h1>
        <sg-organisms/>
      </section>
      <section class="mb-10">
        <h1 class="text-h1 mb-10">Molécules</h1>
        <sg-molecules/>
      </section>
      <section class="mb-10">
        <h1 class="text-h1 mb-10">Atomes</h1>
        <sg-atoms/>
      </section>
      <section class="mb-10">
        <h1 class="text-h1 mb-10">Animations de texte</h1>
        <sg-motion-text/>

      <section class="mb-10">
        <h1 class="text-h1 mb-10">Typographie</h1>
        <sg-typography/>
      </section>
      <section class="mb-10">
        <h1 class="text-h1 mb-10">Neumorphism ?</h1>
        <sg-neumorphism/>
      </section>

      </section>
    </v-container>
  </div>
</template>

<script>
import SgFiles from "@/style-guide/sg-files";
import SgTypography from "@/style-guide/sg-typography";
import SgMotionText from "@/style-guide/sg-motion-text";
import SgNeumorphism from "@/style-guide/sg-neumorphism";
import SgOrganisms from "@/style-guide/sg-organisms";
import SgMolecules from "@/style-guide/sg-molecules";
import SgAtoms from "@/style-guide/sg-atoms";
export default {
  name: "style-guide",
  components: {SgAtoms, SgMolecules, SgOrganisms, SgNeumorphism, SgMotionText, SgTypography, SgFiles},
  data(){
    return{}
  }
}
</script>
<style lang="less">

</style>

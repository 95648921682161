<template>
<button
    class="io-btn-text io-btn"
    :class="{small:small}"
>
  <slot></slot>
</button>
</template>

<script>
import IoBtnMixin from "@/components/atoms/IoBtnMixin";

export default {
  name: "io-btn-text",
  mixins:[IoBtnMixin]
}
</script>

<style lang="less">
  .io-btn{
    &.io-btn-text{
      user-select: none;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;
      font-size: 11px !important;
      box-shadow: none ;
      background-color: transparent;
      color: var(--color-grey-blue-dark);
      &.small{
        font-size: 11px !important;
        font-weight: 800 !important;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
</style>
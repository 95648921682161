<template>
  <div class="release-updater">
    <io-text-regular class="mb-3" v-if="$ui.releaseUpdater.message">
      <div class="d-flex justify-space-between align-center">
        <span class="mr-5">{{$t("updater.status."+$ui.releaseUpdater.message)}}</span>
        <v-progress-circular
            v-if="$ui.releaseUpdater.downloadProgress"
            class="mb-3"
            color="var(--color-green)"
            :value="$ui.releaseUpdater.downloadProgress"
        />
      </div>
    </io-text-regular>
    <div class="d-flex">
      <io-btn
          @click.native="$ui.releaseUpdater.updateAndRestart()"
          v-if="$ui.releaseUpdater.available"
          small class="mb-5 grow">
        {{$t("updater.install")}}
      </io-btn>
    </div>
    <div class="d-flex justify-space-between ">
      <io-text-log>{{$t("updater.version-label")}} {{$ui.about.version}}</io-text-log>
      <io-text-log v-if="$ui.releaseUpdater.nextVersion">
        {{$t("updater.new-version")}}
        {{$ui.releaseUpdater.nextVersion}}
      </io-text-log>
    </div>
  </div>
</template>

<script>
import IoTextLog from "@/components/atoms/io-text-log";
import IoBtn from "@/components/atoms/io-btn";
import IoTextRegular from "@/components/atoms/io-text-regular";
export default {
  name: "release-updater",
  components: {IoTextRegular, IoBtn, IoTextLog}
}
</script>

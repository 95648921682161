<template>
<div class="icon-user"
     :style="{
        color:color,
        width:size,
        height:size
     }"
  >
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(0 -88)" >
        <path fill="currentColor" d="M12.008,92.667a4,4,0,1,0,4,4A4,4,0,0,0,12.008,92.667Zm1.885,5.886a2.668,2.668,0,1,1,.78-1.886A2.658,2.658,0,0,1,13.893,98.553Z"/>
        <path fill="currentColor" d="M22,99.993A10,10,0,1,0,3.935,105.91v0l.018.022a9.994,9.994,0,0,0,14.32,1.85,10.112,10.112,0,0,0,1.459-1.445l.348-.427-.01-.013A9.991,9.991,0,0,0,22,99.993Zm-9.992,8.672c-.3,0-.591-.015-.878-.043l-.022,0c-.13-.013-.258-.03-.385-.05-.025,0-.05-.008-.075-.01-.11-.018-.22-.037-.328-.06l-.115-.022c-.1-.02-.193-.043-.288-.068l-.14-.035c-.084-.022-.172-.048-.257-.072-.052-.015-.1-.033-.155-.048-.082-.027-.162-.055-.242-.083-.053-.017-.105-.037-.158-.057-.08-.03-.16-.06-.237-.092l-.153-.065c-.08-.033-.158-.071-.235-.105-.05-.023-.1-.048-.147-.071-.078-.037-.153-.077-.23-.117l-.145-.078c-.073-.042-.145-.082-.218-.125l-.152-.092c-.065-.04-.13-.082-.2-.125s-.113-.075-.17-.115-.11-.077-.163-.118-.132-.1-.2-.144c-.043-.033-.083-.065-.125-.1-.078-.063-.155-.125-.233-.193-.025-.022-.052-.045-.08-.067-.092-.083-.182-.166-.272-.253-.01-.01-.02-.017-.03-.027-.1-.1-.2-.2-.3-.308.135-.145.275-.283.418-.417a8.667,8.667,0,0,1,12.242.41A8.643,8.643,0,0,1,12.008,108.665Zm7.167-3.793a9.775,9.775,0,0,0-.82-.757,10,10,0,0,0-13.515.757,8.666,8.666,0,0,1,7.168-13.539h.015a8.672,8.672,0,0,1,8.254,11.259A8.559,8.559,0,0,1,19.175,104.872Z"/>
        <path fill="currentColor" d="M12.023,91.333h-.015A8.666,8.666,0,0,0,4.84,104.872a10,10,0,0,1,13.515-.757,9.775,9.775,0,0,1,.82.757,8.651,8.651,0,0,0,1.5-4.872A8.665,8.665,0,0,0,12.023,91.333Zm-.015,9.334a4,4,0,1,1,4-4A4,4,0,0,1,12.008,100.667Z"
              opacity="0.5"/>
      </g>
  </svg>
</div>
</template>

<script>
export default {
  name: "icon-user",
  props:{
    color:{
      type:String,
      default:"#888888"
    },
    size:{
      type:String,
      default:"36px"
    }
  }
}
</script>

<style lang="less">
.icon-user{
  position: relative;
  >svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
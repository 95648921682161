<!--
A simple rounded square  wrapper
-->
<template>
<div  class="io-square-avatar"
      :style="{
        'background-color': backgroundColor,
        'color': foregroundColor
      }"
>
  <slot/>
</div>
</template>

<script>
export default {
  name: "io-square-avatar",
  props:{
    backgroundColor:{
      type:String,
      default:"var(--color-grey-ultra-light)"
    },
    foregroundColor:{
      type:String,
      default:"var(--color-grey-light)"
    }
  }
}
</script>

<style lang="less">
.io-square-avatar{
  border-radius: 12px;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
<template>
<div class="io-text-regular"  :style="{color:textColor}">
  <slot></slot>
</div>
</template>

<script>
import IoTextMixin from "@/components/atoms/IoTextMixin";
export default {
  name: "io-text-regular",
  mixins:[IoTextMixin],
  props:{
    color:{
      type:String,
      default:"title"
    }
  }
}
</script>

<style scoped>

</style>
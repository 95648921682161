<template>
<v-dialog v-model="show"
          persistent fullscreen
          transition="none"
>
  <io-disabler class="d-flex justify-center align-center fill-height flex-column" >
    <v-spacer/>
    <io-card style="max-width: 800px;" class="mb-10">
      <io-text-title>{{$t('dialog-legal.title')}}</io-text-title>
      <io-spacing/>
      <io-text-label>{{$t('dialog-legal.body')}}</io-text-label>
      <io-spacing/>
      <template slot="actions">
        <v-row class="text-center align-center">
          <v-col class="text-md-left">
            <io-btn-text @click.native="$emit('download')">
              {{$t('dialog-legal.dwd-btn')}}
            </io-btn-text>
          </v-col>
          <v-spacer class="d-none d-sm-block"/>
          <v-col class="text-md-right">
            <io-btn small @click.native="$emit('accept')">
              {{$t('dialog-legal.accept-btn')}}
            </io-btn>
          </v-col>
        </v-row>
      </template>
    </io-card>
  </io-disabler>

</v-dialog>

</template>

<script>
import IoCard from "@/components/atoms/io-card";
import IoTextTitle from "@/components/atoms/io-text-title";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoSpacing from "@/components/atoms/io-spacing";
import IoBtnText from "@/components/atoms/io-btn-text";
import IoBtn from "@/components/atoms/io-btn";
import IoDisabler from "@/components/atoms/io-disabler";
export default {
  name: "io-dialog-legal",
  components: {IoDisabler, IoBtn, IoBtnText, IoSpacing, IoTextLabel, IoTextTitle, IoCard},
  props:{
    visible:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    show(){
      return this.visible
    }
  }
}
</script>
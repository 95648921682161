<template>
<button :is="tag?tag:'button'" class="io-btn "
        :class="{
            'small':small,
            'has-icon':hasIcon,
        }"
        v-ripple="{ center: false }">
        <io-icon-elevate  v-if="hasIcon">
          <slot name="icon"></slot>
        </io-icon-elevate>
        <span class="txt">
          <slot/>
        </span>

</button>
</template>

<script>
import IoBtnMixin from "@/components/atoms/IoBtnMixin";
import IoIconElevate from "@/components/atoms/io-icon-elevate";

export default {
  name: "io-btn",
  components: {IoIconElevate},
  mixins:[IoBtnMixin],
  props:["tag"],
  computed:{
    hasIcon(){
      return !!this.$slots['icon']
    }
  }
}
</script>

<style lang="less">
.io-btn{
  user-select: none;
  background-color: var(--color-velvet);
  text-decoration: none !important;
  outline: none !important;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  .txt{
    font-size: var(--io-text-label-size) !important;
    color: #fff ;
  }

  font-weight: 800 !important;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  white-space: nowrap;
  height: 50px;
  padding: 0 20px;
  border-radius: 90px;
  box-shadow:var(--io-elevation-helicopter);
  @media(min-width: @screen-md){
    height: 90px;
    padding: 0 60px;
  }
  &.small{
    height: 40px;
    padding: 0 15px;
    @media(min-width: @screen-md){
      height: 60px;
      padding: 0 30px;
    }
    .txt{
      font-size: 11px !important;
    }

    font-weight: 400 !important;
  }
  @media(hover: hover){
    &:hover{
      filter: hue-rotate(5deg);
    }
  }


  &.has-icon{
    padding-left: 15px;
    padding-right: 30px;
  }





}
</style>
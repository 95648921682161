<template>



  <div>

    <sg-divider>io-text-title</sg-divider>
    <io-text-title>Ceci est un titre</io-text-title>
    <io-text-title>Inatysco permet d'échanger des fichiers</io-text-title>

    <sg-divider>io-text-title-2</sg-divider>

    <io-text-title2>Ceci est un titre 2</io-text-title2>
    <io-text-title2>Créez une connexion</io-text-title2>

    <sg-divider>io-text-label</sg-divider>

    <io-text-label>Ceci est un label utilisé pour les noms ou les explications</io-text-label>
    <io-text-label>Juliette</io-text-label>
    <io-text-label>Raison du pseudo. On pourra le changer dans les paramètres</io-text-label>
    <io-text-label>Ajoutez vos fichiers</io-text-label>


    <sg-divider>io-text-regular</sg-divider>

    <io-text-regular>Ceci est un texte normal</io-text-regular>
    <io-text-regular color="file">Mon nom de fichier</io-text-regular>
    <io-text-regular>Créez un nouveau chemin</io-text-regular>
    <io-text-regular>Placeholder - Entrez le code ici</io-text-regular>


    <sg-divider>io-text-code-lg</sg-divider>

    <io-text-code-lg>Ceci est un code LAAARGE</io-text-code-lg>
    <io-text-code-lg>X2B12</io-text-code-lg>

    <sg-divider>io-text-code</sg-divider>

    <io-text-code>X2B12 - Ceci est un code</io-text-code>
    <io-text-code>X2B12</io-text-code>

    <sg-divider>io-text-log</sg-divider>
    <io-text-log>Ceci est une log</io-text-log>
    <io-text-log color="file">240 Mo</io-text-log>
    <io-text-log color="file">.mp4</io-text-log>
    <io-text-log>hash-xdg14c</io-text-log>
    <io-text-log>16h30 chiffrement en cours</io-text-log>

    <io-spacing/>

    <io-card class="fill-height">
        <io-text-title2 class="play-wght">Satoshi Nakamoto</io-text-title2>
        <hr class="my-5">
        <io-text-regular class="text-body-1">
          Il est le concepteur de la première base de données construite avec une chaîne de blocs ou blockchain.
          Il est également le premier à résoudre le problème de la double dépense pour une monnaie numérique en utilisant un réseau pair-à-pair.
          <br><br>
          Les algorythmes d'<b>Inatysco</b> s'appuient sur cette logique.
          <br><br>
          <b>Satoshi</b> est aussi le nom de la typographie utilisée par inatysco dans sa communication.
        </io-text-regular>
        <template v-slot:actions>
          <v-spacer/>
          <io-btn
                  small
                  target="_blank"
                  href="https://www.fontshare.com/fonts/satoshi">
                  En savoir plus
          </io-btn>
        </template>
      <v-card-actions>

      </v-card-actions>
    </io-card>

    <io-spacing/>

    <io-card  class="fill-height">
        <io-text-code-lg class="play-wght">JetBrain</io-text-code-lg>
        <hr class="my-5">
        <io-text-code>
          JetBrain est un éditeur de logiciels pour développeurs qui possède des bureaux à Prague, Saint-Pétersbourg, Boston, Moscou et Munich.
          <br><br>
          Les dévelopeurs chez <b>Inatysco</b> utilisent au quotidien les logiciels de cet éditeur.
          <br><br>
          <b>JetBrains Mono</b> est la typographie utilisée pour éditer du code dans l'univers JetBrain.
          C'est aussi la typographie que nous utilisons pour afficher des codes ou des données techniques dans les logiciels que nous produisons.
        </io-text-code>
        <template v-slot:actions>
          <v-spacer/>
          <io-btn
              small
             target="_blank"
             href="https://fonts.google.com/specimen/JetBrains+Mono"
          >En savoir plus</io-btn>
        </template>
    </io-card>

  </div>


</template>

<script>
import IoTextTitle from "@/components/atoms/io-text-title";
import IoTextCodeLg from "@/components/atoms/io-text-code-lg";
import IoTextCode from "@/components/atoms/io-text-code";
import IoTextTitle2 from "@/components/atoms/io-text-title-2";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoTextLog from "@/components/atoms/io-text-log";
import IoTextRegular from "@/components/atoms/io-text-regular";
import SgDivider from "@/style-guide/sg-divider";
import IoCard from "@/components/atoms/io-card";
import IoSpacing from "@/components/atoms/io-spacing";
import IoBtn from "@/components/atoms/io-btn";
export default {
  name: "sg-typography",
  components: {
    IoBtn,
    IoSpacing,
    IoCard,
    SgDivider,
    IoTextRegular,
    IoTextLog,
    IoTextLabel,
    IoTextTitle2,
    IoTextCode,
    IoTextCodeLg,
    IoTextTitle
  },
  data(){
    return{}
  }
}
</script>

<style lang="less">
.play-wght{
  animation: play-wght 5s linear infinite alternate;
  @keyframes play-wght{
    0% {
      font-variation-settings: 'wght' 100 ;
    }
    100% {
      font-variation-settings: 'wght' 900;
    }
  }
}
</style>
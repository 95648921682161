import FileAlt from "./FileAlt";
import FileMeta from "./FileMeta";
import SimplePeerFiles from "@inatysco/simple-peer-files";
import axios from "axios";

export default class FileTransferService {

    constructor(peer, partnerName = "Partner") {
        this.urlTransaction = 'https://rtc-signaling.data-nostrum.fr/api/v1/transaction';

        /**
         * WebRTC connection service
         * @type {SimplePeer}
         */
        this.peer = peer;
        /**
         * Name of the exchange partner
         * @type {string}
         */
        this.partnerName = partnerName;
        /**
         * Service allowing file exchanges
         * @type {SimplePeerFiles}
         */
        this.spf = new SimplePeerFiles();
        /**
         * List of files being transferred both ways
         * @type {FileAlt[]}
         */
        this.files = [];

        this.peer.on('data', data => {
            console.debug("Received via webRTC : " + data);
            const json = JSON.parse(data);
            if (json.type === "fileOffer") {
                this._acceptFileOffer(json.meta);
            } else if (json.type === "fileAcceptation") {
                this._sendFile(json.meta);
            } else if (json.type === "status") {
                const fileAlt = this.files.find(f => f.meta.id === json.id);
                fileAlt.status = json.status;
            } else if (json.type === "h") {
                const fileAlt = this.files.find(f => f.meta.id === json.id);
                fileAlt.fairswapFileReceiver.getR().then(r => {
                    const post = {
                        fileHash: json.h.toString(),
                        merkleRoot: r.toString(),
                        receiver: this.userName,
                        sender: this.partnerName
                    };
                    const header = {headers: {'Content-Type': 'application/json'}};
                    axios.post(this.urlTransaction, JSON.stringify(post), header).then(response => {
                        const transactionId = response.data.id;
                        this.peer.send(JSON.stringify({
                            type: "transactionId",
                            id: transactionId,
                            fileId: fileAlt.meta.id
                        }));

                        let key = null;
                        new Promise(resolve => {
                            const loop = () => {
                                axios.get(this.urlTransaction + "/" + transactionId).then(response2 => {
                                    if (response2.data.key) {
                                        fileAlt.statusKeyReceived();
                                        this._notifyStatusChange(fileAlt);
                                        key = response2.data.key;
                                        resolve();
                                    } else {
                                        setTimeout(loop, 2000);
                                    }
                                });
                            }
                            setTimeout(loop, 2000);
                        }).then(() => {
                            fileAlt.fairswapFileReceiver.getFile(key, json.h)
                                .then(file => {
                                    fileAlt.file = file;
                                    fileAlt.statusDecoded();
                                    this._notifyStatusChange(fileAlt);
                                })
                                .catch(() => {
                                    fileAlt.statusError();
                                    this._notifyStatusChange(fileAlt);
                                })
                        });
                    })
                });
            } else if (json.type === "transactionId") {
                axios.get(this.urlTransaction + "/" + json.id).then(response => {
                    const fileAlt = this.files.find(f => f.meta.id === json.fileId);
                    if (fileAlt && response.data.fileHash === fileAlt.fairswapFileSender.h.toString()
                        && response.data.merkleRoot === fileAlt.fairswapFileSender.r.toString()) {
                        fileAlt.statusVerified();
                        this._notifyStatusChange(fileAlt);
                        const body = {key: fileAlt.fairswapFileSender.key};
                        const header = {headers: {'Content-Type': 'application/json'}};
                        axios.patch(this.urlTransaction + "/" + json.id, JSON.stringify(body), header).then();
                    } else {
                        console.error("Response file hash = " + response.data.fileHash + ", expected = " + fileAlt.fairswapFileSender.h.toString());
                        console.error("Response merkle root = " + response.data.merkleRoot + ", expected = " + fileAlt.fairswapFileSender.r.toString());
                        // TODO : ERROR
                    }
                });
            } else if (json.type === "transferError") {
                const fileAlt = this.files.find(f => f.meta.id === json.id);
                fileAlt.resetFairswapFileReceiver();
                this.spf.receive(this.peer, fileAlt.meta.id, fileAlt.fairswapFileReceiver).then(transfer => {
                    fileAlt.transfer = transfer;
                    transfer.on('done', () => {
                        fileAlt.statusTransferred();
                    });
                    transfer.on('cancel', () => {
                        const index = this.files.indexOf(fileAlt);
                        this.files.splice(index, 1);
                    });
                    transfer.on('cancelled', () => {
                        fileAlt.statusCanceled();
                        const index = this.files.indexOf(fileAlt);
                        this.files.splice(index, 1);
                    });
                    transfer.on('error', (error) => {
                        console.warn("Error receiving file: " + fileAlt.meta.name + " / " + fileAlt.meta.id +
                            ". With error: " + JSON.stringify(error));
                        fileAlt.statusError();
                    });
                });
                this.peer.send(JSON.stringify({type: "readyForRetry", id: fileAlt.meta.id}));
                fileAlt.statusAccepted();
            } else if (json.type === "readyForRetry") {
                const fileAlt = this.files.find(f => f.meta.id === json.id);
                this._sendFile(fileAlt.meta);
            }
        });
    }

    get userName() {
        return window.$services.user.userName;
    }

    _notifyStatusChange(fileAlt) {
        console.debug("Sending status " + fileAlt.status + " for file " + fileAlt.meta.name + " (" + fileAlt.meta.id + ")");
        this.peer.send(JSON.stringify({type: "status", id: fileAlt.meta.id, status: fileAlt.status}));
    }

    /**
     *
     * @param {FileAlt} fileAlt
     */
    downloadFile(fileAlt) {
        window.$utils.files.download(fileAlt.file, fileAlt.meta.name);
        fileAlt.statusDownloaded();
        this._notifyStatusChange(fileAlt);
    }

    /**
     * Start process to send file to partner:
     * 1 - An file offer is sent to the partner
     * 2 - The partner accept the file offer
     * 3 - The file is sent through WebRTC connection
     * @param {File} file
     */
    sendFile(file) {
        const meta = new FileMeta();
        meta.initFromFile(file);
        const fileAlt = new FileAlt();
        fileAlt.meta = meta;
        fileAlt.file = file;
        fileAlt.isUpload = true;
        this.files.push(fileAlt);

        this._sendFileOffer(fileAlt).then(fileAltReceived => {
            fileAltReceived.statusOffered();
        });
    }

    /**
     *
     * @param {FileAlt} fileAlt
     */
    pauseFileTransfer(fileAlt) {
        fileAlt.pause();
    }

    /**
     *
     * @param {FileAlt} fileAlt
     */
    resumeFileTransfer(fileAlt) {
        fileAlt.resume();
    }

    /**
     *
     * @param {FileAlt} fileAlt
     */
    cancelFileTransfer(fileAlt) {
        fileAlt.cancel();
    }

    /**
     * Send the file offer to the partner
     * @param {FileAlt} fileAlt
     * @returns {Promise}
     * @private
     */
    _sendFileOffer(fileAlt) {
        return new Promise(resolve => {
            fileAlt.fairswapFileSender.prepareFile(fileAlt.file, () => {
                fileAlt.statusEncoded();
                fileAlt.meta.hash = fileAlt.fairswapFileSender.h.toString();
                fileAlt.meta.totalSize = fileAlt.fairswapFileSender.size;
                fileAlt.meta.chunkNumber = fileAlt.fairswapFileSender.encodedChunks.length + fileAlt.fairswapFileSender.encodedTreeBlocks.length;
                fileAlt.meta.chunkFileNumber = fileAlt.fairswapFileSender.encodedChunks.length;
                const type = "fileOffer";
                const data = {type, meta: fileAlt.meta};
                this.peer.send(JSON.stringify(data));
                resolve(fileAlt);
            });
        });
    }

    /**
     * Accept the file offer received from the partner
     * @param {FileMeta} meta Metadata of the file
     * @private
     */
    _acceptFileOffer(meta) {
        const type = "fileAcceptation";
        const data = {type, meta};
        const fileAlt = new FileAlt();
        fileAlt.statusOffered();
        fileAlt.meta = meta;
        fileAlt.isUpload = false;
        this.files.push(fileAlt);
        this.spf.receive(this.peer, meta.id, fileAlt.fairswapFileReceiver).then(transfer => {
            fileAlt.transfer = transfer;
            transfer.on('done', () => {
                fileAlt.statusTransferred();
            });
            transfer.on('cancel', () => {
                const index = this.files.indexOf(fileAlt);
                this.files.splice(index, 1);
            });
            transfer.on('cancelled', () => {
                fileAlt.statusCanceled();
                const index = this.files.indexOf(fileAlt);
                this.files.splice(index, 1);
            });
            transfer.on('error', (error) => {
                console.warn("Error receiving file: " + fileAlt.meta.name + " / " + fileAlt.meta.id +
                    ". With error: " + JSON.stringify(error));
                fileAlt.statusError();
            });
        });
        this.peer.send(JSON.stringify(data));
        fileAlt.statusAccepted();
    }

    /**
     * Send the actual file to the partner after its acceptation of the file offer
     * @param {FileMeta} meta
     * @private
     */
    _sendFile(meta) {
        const fileAlt = this.files.find(d => d.meta.id === meta.id);
        fileAlt.statusAccepted();
        this.spf.send(this.peer, fileAlt.meta.id, fileAlt.file).then(transfer => {
            fileAlt.transfer = transfer;
            transfer.on('done', () => {
                fileAlt.statusTransferred();
                this.peer.send(JSON.stringify({
                    type: 'h',
                    h: fileAlt.fairswapFileSender.h.toString(),
                    id: fileAlt.meta.id
                }));
            })
            transfer.on('cancel', () => {
                fileAlt.statusCancel();
            });
            transfer.on('cancelled', () => {
                fileAlt.statusCanceled();
            });
            transfer.on('error', (error) => {
                console.warn("Error sending file: " + fileAlt.meta.name + " / " + fileAlt.meta.id +
                    ". With error: " + JSON.stringify(error));
                fileAlt.statusError();
                this._notifyStatusChange(fileAlt);
                this.peer.send(JSON.stringify({type: "transferError", id: fileAlt.meta.id}))
            });

            transfer.start(fileAlt.fairswapFileSender);
        });
    }
}

<template>
<div class="io-create-connection">
      <div class="bg fill-height">
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="var(--color-green)"/>
        <io-btn
            v-else
            small @click.native="$emit('create-room')">
          {{$t('start-connection.create-btn')}}
          <template slot="icon">
            <icon-user
                size="40px"
                color="var(--color-velvet)"
            />
          </template>
        </io-btn>
      </div>
      <div class="bg">
        <io-text-label v-if="loading">
          <io-motion-text-slide
              class=" ml-5"
              :items="[
                  $t('connection.wait'),
                  '...'
              ]"
          />
        </io-text-label>
        <div v-else>
          <io-text-title2>
            {{$t('start-connection.i-have-code-label')}}
          </io-text-title2>
          <io-spacing tiny />
          <io-text-field-submit
              @submit="$emit('connect-to-room',inputCode)"
              :placeholder="$t('start-connection.i-have-code-placeholder')"
              v-model="inputCode"
          />
        </div>
      </div>

</div>
</template>

<script>
import IoBtn from "@/components/atoms/io-btn";
import IoTextTitle2 from "@/components/atoms/io-text-title-2";
import IoSpacing from "@/components/atoms/io-spacing";
import IoTextFieldSubmit from "@/components/molecules/io-text-field-submit";
import IconUser from "@/components/atoms/icons/icon-user";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoMotionTextSlide from "@/components/atoms/motion-text/io-motion-text-slide";
export default {
  name: "io-create-connection",
  components: {
    IoMotionTextSlide,
    IoTextLabel,
    IconUser,
    IoTextFieldSubmit,
    IoSpacing,
    IoTextTitle2,
    IoBtn
  },
  props:{
    loading:{
      type:Boolean,
      default:false
    },
  },
  data(){
    return{
      inputCode:""
    }
  }
}
</script>

<style lang="less">
.io-create-connection{

  text-align: center;
  box-shadow: var(--io-elevation-plane);
  overflow: hidden;
  border-radius: 8px;
  .bg{
    background-color: white;
    padding: 30px;
    &:nth-child(2){
      margin-top: 4px;
    }
  }

  @media(min-width: 700px){
    --h:150px;
    --w:660px;
    @media(min-width: @screen-md){
      --h:200px;
      --w:810px;
    }
    width: var(--w);
    //height: var(--h);

    border-radius: var(--h);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bg{
      box-shadow: var(--io-elevation-inner);
      margin-top: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      @media(min-width: @screen-md) {
        padding: 0 50px;
      }
      width: calc( 50% - 2px);
      height: var(--h);
    }
  }

}

</style>
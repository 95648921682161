/**
 * Metadata of a file
 * It contains copy of native file object attributes and additional attributes.
 * This class allow to send the metadata of a file to a partner without send
 * the actual file (The Blob). It is for example useful to send the file offer
 * in:
 * @see {SignalingService}
 *
 */
export default class FileMeta {

    constructor() {
        /**
         * Id of the file. It's a generated value used to identify the
         * file for the transfer.
         * @type {string}
         */
        this.id = "";
        /**
         *  File name
         * @type {string}
         */
        this.name = "";
        /**
         * File size in octets
         * @type {number}
         */
        this.size = 0;
        /**
         * File Mime type
         * @type {string}
         */
        this.type = "";
        /**
         * File extension
         * @type {string}
         */
        this.extension = "";
        /**
         *
         * @type {number}
         */
        this.totalSize = 0;
        /**
         *
         * @type {number}
         */
        this.chunkNumber = 0;
        /**
         *
         * @type {number}
         */
        this.chunkFileNumber = 0;
        /**
         * Hash of the file
         * @type {string}
         */
        this.hash = "";
    }

    /**
     * Initialize attributes from a file object
     * @param {File} file
     */
    initFromFile(file) {
        this.id = this._getFileId();
        this.name = file.name;
        this.size = file.size;
        this.type = file.type;
        this.extension = file.name.split('.').pop();
    }

    /**
     * Generate a file id as a random UUID
     * @returns {string} UUID
     * @private
     */
    _getFileId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

import IOLocalesManager from "@/models/locales/IOLocalesManager";
import Utils from "@/utils/Utils";

//later...
//const electron = require('electron');
//const remote = electron.remote;
//const isDevelopment = process.mainModule.filename.indexOf('app.asar') === -1;

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import vuetify from '@/plugins/vuetify';
import VueRouter from 'vue-router'
import router from '@/plugins/router'
import Ui from "./components/Ui";
import Services from "@/services/Services";

Vue.config.productionTip = false;
Vue.use(VueRouter);
window.$router=router;

/**
 * Global user interface settings, states, utilities...
 * @type {Ui}
 */
window.$ui=new Ui();
Vue.prototype.$ui = Vue.observable(window.$ui);

/**
 * Some shared utilities
 * @type {Utils}
 */
window.$utils=new Utils();
Vue.prototype.$utils = Vue.observable(window.$utils);

/**
 * @type {Services}
 */
window.$services = new Services();
Vue.prototype.$services = Vue.observable(window.$services);

/**
 *  Locales manager
 * @type {IOLocalesManager}
 */
window.$locales=new IOLocalesManager();
Vue.prototype.$locales = Vue.observable(window.$locales);
window.$locales.addLocale(
    "fr",
    "français"
);
window.$locales.addLocale(
    "en",
    "english"
);

window.$locales.currentLocale="fr";
Vue.use(VueI18n);
let i18n = new VueI18n({
    locale: window.$locales.currentLocale.code, // set locale
    messages:
        {
        'fr': require("./assets/locales-fr.json"),
        'en': require("./assets/locales-en.json"),
        }
    }
);
window.$t=i18n; //translate from anywhere (not only vue components)
window.$locales.on("change-current-language",function(){
    i18n.locale = window.$locales.currentLocale.code;
})
/**
 * To translate from anywhere (not only vuejs)
 * @type {VueI18n}
 */

new Vue({
    i18n ,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');








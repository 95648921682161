<template>
<div class="io-file-meta mx-2">
  <io-text-regular
      color="var(--color-daaark)"
      :title="name"
      class="file-name">
    {{name}}
  </io-text-regular>
  <div class="d-flex mt-1">
    <div class="text-dot mr-4">
      <io-text-log class="t">{{ $utils.files.humanFileSize(size)}}</io-text-log>
      <io-dot :color="colorSize"></io-dot>
    </div>
    <div class="text-dot">
      <io-text-log class="t">.{{extension}}</io-text-log>
      <io-dot :color="colorMime"></io-dot>
    </div>
  </div>
</div>
</template>

<script>
import FileAltMixin from "@/components/file-items/FileAltMixin";
import IoTextRegular from "@/components/atoms/io-text-regular";
import IoTextLog from "@/components/atoms/io-text-log";
import IoDot from "@/components/atoms/io-dot";

export default {
  name: "io-file-meta",
  components: {IoDot, IoTextLog, IoTextRegular},
  mixins:[FileAltMixin]
}
</script>

<style lang="less">
.io-file-meta{
  width: 150px;
  .file-name{
    //outline: 1px solid #eee;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    height: 1.5em;
  }
  .text-dot{
    display: flex;
    align-items: center;
    >*{
      //outline: 1px solid red;
      flex-grow: 0;
      flex-shrink: 0;
    }
    >.t{
      min-width: 35px;
      margin-right: 5px;
      white-space: nowrap;
    }

    .io-dot{
      margin-top: 1px;
      flex-shrink: 0;
    }
  }
}
</style>
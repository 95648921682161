<template>
<div class="page d-flex justify-center align-center">
  <v-container >
    <v-row justify="center" class="text-center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <io-text-title>{{$t("nickname.title")}}</io-text-title>
        <io-spacing large/>
        <io-text-label>
         {{$t("nickname.text")}}
        </io-text-label>
        <io-spacing large/>



        <io-user-name-input
            class="mx-auto"
            v-model="$services.user.userName"
            :loading="loading"
            @submit="onSetName"
        />

        <io-spacing large/>
        <transition name="fade-height">
          <io-text-label color="#f00" v-if="error">
            {{$t(error)}}
          </io-text-label>
        </transition>

      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import IoTextTitle from "@/components/atoms/io-text-title";
import IoSpacing from "@/components/atoms/io-spacing";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoUserNameInput from "@/components/organisms/io-user-name-input";
export default {
  name: "my-name-is",
  components: { IoUserNameInput, IoTextLabel, IoSpacing, IoTextTitle},
  data(){
    return{
      error:"",
      loading:false
    }
  },
  methods:{
    onSetName(){
      let me=this;
      this.error=this.$services.user.error;
      if(!this.error){
        me.loading=true;
        this.$ui.connect(
            me.$ui.tempRoomId==="create"?null:me.$ui.tempRoomId,
            function(){
              if(me.$ui.tempRoomId==="create"){
                me.$router.push('code');
              }else{
                me.$router.push('room');
              }
            },
            function(error){
              me.loading=false;
              me.error="connection.errors."+error;
              if(error==="INVALID_ROOM_ID"){
                //user is invited to type room id again
                setTimeout(function(){
                  me.$router.push({name:"home"})
                },2000)
              }
            }
        );
      }
    }
  }
}
</script>

<style scoped>

</style>

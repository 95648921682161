export default {
    props:{
        roomName:{
            type:String,
            default:""
        },
        connected:{
            type:Boolean,
            default:false
        },
        userName:{
            type:String,
            default:""
        },
        partnerName:{
            type:String,
            default:""
        },
    },
}
export default {
    props:{
        color:{
            type:String,
            default: "#f00"
        }
    },
    computed:{
        textColor(){
            switch (this.color){
                case "title":
                return "var(--color-title)";

                case "file":
                return "var(--color-file)";

                case "log":
                return "var(--color-log)";
            }
            return this.color;
        }
    }

}
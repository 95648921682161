<template>
<div class="io-background">
  <video class="bg-video"
         :src="$ui.path.publicVideo('RIPPLE2.mp4')"
         loop muted autoplay
  />
</div>
</template>

<script>
/**
 * TODO on teste en vidéo si ça le fait et si c'est faisable on fera du code
 */
export default {
  name: "io-background"
}
</script>

<style lang="less">
.io-background{
  background: linear-gradient(
      0deg, rgba(245,247,251,1) 0%,
      rgba(236,238,245,1) 100%
  );
  .bg-video{
    background-color: rgba(236,238,245,1);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
<template>
<div>
  <hr class="my-5">
  <kbd><slot></slot></kbd>
  <hr class="my-5">
</div>
</template>

<script>
export default {
  name: "sg-divider"
}
</script>

<style scoped>

</style>
<template>
<div class="page connection-code d-flex justify-center align-center">
  <v-container class="text-center" @copy.stop="copyToClipBoard">

    <!-- titles -->
    <v-row justify="center">
      <v-col sm="10" lg="10">
        <io-text-label>{{$t("connection-code.baseline")}}</io-text-label>
        <io-text-title>{{$t("connection-code.title")}}</io-text-title>
      </v-col>
      <v-col cols="12">
        <io-spacing class="d-none d-md-block"/>
      </v-col>
    </v-row>

    <!-- users + code -->
    <v-row class="u-code-u" justify="center">
      <!-- user -->
      <v-col
          cols="6" order="2"
          md="2" order-md="1"
          class="justify-end">
        <io-chip-player
            is-connected
            :user-name="$services.user.userName"/>
      </v-col>

      <!-- partner -->
      <v-col
          cols="6" order="3"
          md="2" order-md="3">
        <io-chip-player
            is-partner
            :is-connected="$services.rtcConnection.isConnected"
            :user-name="$services.rtcConnection.partnerName"/>
      </v-col>

      <!-- code box -->
      <v-col
          class="col-main"
          cols="12" order="1"
          md="8" order-md="2">
        <div class="line d-none d-md-block"></div>
        <io-card class="text-center mx-auto">
          <io-text-code-lg>
            <io-motion-text-encoding
                @click.native="copyToClipBoard"
                v-ripple
                :text="$services.rtcConnection.roomId"
                possible-chars="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
                min-length="16" max-length="16"
            />
          </io-text-code-lg>
          <io-btn-text v-if="!sentCode"
                       small
                       @click.native="sentCode=true">
            {{$t("connection-code.wait-button")}}
          </io-btn-text>
          <div v-else>
            <io-text-label>
              <io-motion-text-slide
                  class="my-5"
                  :items="[
                  $t('connection.wait'),
                  '...',
                  $t('connection.waiting-partner'),
                  '...'
              ]"
              />
            </io-text-label>
            <v-progress-circular
                indeterminate
                color="var(--color-green)"
            />
          </div>
          <io-spacing/>
          <div style="width: 100%" class="d-flex flex-column">
            <io-btn
                v-if="!showMailInput"
                @click.native="showMailInput=true"
                small
                target="_blank"
            >{{$t('connection-code.mail-button')}}</io-btn>
            <io-text-field-submit
                v-if="showMailInput"
                class="d-inline-flex"
                color="velvet"
                type="email"
                @submit="sendMail"
                :placeholder="$t('connection-code.mail-placeholder')"
                v-model="email"
            />
            <io-text-label
                v-if="error"
                class="mt-5"
                color="red">
              {{error}}
            </io-text-label>
          </div>

        </io-card>
      </v-col>
    </v-row>
  </v-container>



</div>
</template>

<script>

import IoTextTitle from "@/components/atoms/io-text-title";
import IoSpacing from "@/components/atoms/io-spacing";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoCard from "@/components/atoms/io-card";
import IoTextCodeLg from "@/components/atoms/io-text-code-lg";
import IoBtnText from "@/components/atoms/io-btn-text";
import IoBtn from "@/components/atoms/io-btn";
import IoMotionTextEncoding from "@/components/atoms/motion-text/io-motion-text-encoding";
import IoMotionTextSlide from "@/components/atoms/motion-text/io-motion-text-slide";
import IoChipPlayer from "@/components/molecules/io-chip-player";
const validator = require("email-validator");
import IoTextFieldSubmit from "@/components/molecules/io-text-field-submit";
export default {
  name: "connection-code",
  components: {
    IoTextFieldSubmit,
    IoChipPlayer,
    IoMotionTextSlide,
    IoMotionTextEncoding,
    IoBtn,
    IoBtnText,
    IoTextCodeLg,
    IoCard,
    IoTextLabel,
    IoSpacing,
    IoTextTitle
  },
  data: function () {
    return {
      sentCode:false,
      showMailInput:false,
      email:"",
      error:"",
    }
  },
  mounted() {
    this.testRoomValid();
  },
  computed: {
    /**
     * Il both users are connected to room
     * @return {boolean}
     */
    isRoomReady() {
      console.log("isConnected " + this.$services.rtcConnection.isConnected);
      return this.$services.rtcConnection.isConnected;
    },
    /**
     * the room id
     * @return {string|null}
     */
    roomId(){
      return this.$services.rtcConnection.roomId;
    }
  },
  watch: {
    isRoomReady() {
      let me=this;
      setTimeout(function() {
        me.$router.push('room');
      }, 1000);
    },
    roomId() {
      this.testRoomValid();
    }
  },
  methods: {
    /**
     * Test if the connection is active
     * if it is not, redirect to home page
     */
    testRoomValid(){
      let me=this;
      if (!this.roomId) {
        setTimeout(function() {
          me.$ui.temporaryMessage = me.$t('connection.error.LOST');
          me.$router.push({name: "home"});
        },1000);
      }
    },
    /**
     * Copy the room id to clipboard
     */
    copyToClipBoard(){
      this.$ui.copyToClipboard(this.$services.rtcConnection.roomId);
      this.$ui.temporaryMessage=this.$t('alerts.code-copied');
    },
    /**
     * Send by mail the room id
     */
    sendMail(){
      if(validator.validate(this.email)){
        this.error="";
        let href=`mailto:${this.email}?subject=${this.$t("connection-code.mail-subject")}&body=${this.$services.rtcConnection.roomId}`
        let mail = document.createElement("a");
        mail.setAttribute("target","_blank");
        mail.href = href;
        mail.click();
        this.sentCode=true;
      }else{
        this.error=this.$t("connection-code.mail-not-valid");
      }

    }
  }
}
</script>

<style lang="less">
  .u-code-u{
    >*{
      //outline: 1px dashed pink;
      display: flex;
      align-items: center;
    }
    .col-main{
      position: relative;
      .io-text-code-lg{
        cursor: pointer;
        &:hover{
          opacity: 0.6;
        }
      }
      .line{
        position: absolute;
        width: 100%;
        border-top: 2px dashed #fff;
        left: 0;
      }
      .io-card{
        position: relative;
        flex-grow: 1;
        width: 100%;
        @media(min-width: @screen-sm){
          width: auto;
          max-width: 500px;
        }
      }
    }
  }
</style>

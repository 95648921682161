<template>
<div class="io-dot" :style="{
  width:size,
  height:size,
  'background-color':color
}">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "io-dot",
  props:{
    size:{
      type:String,
      default:"8px"
    },
    color:{
      type:String,
      default:"#808080"
    }
  }
}
</script>

<style lang="less">
.io-dot{
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
}
</style>
<template>
  <v-form
      @submit.prevent="$emit('submit')"
      class="io-text-field-submit"
      :class="{inactive:!active}">
    <v-text-field
        autofocus
        name="name"
        v-model="inputVal"
        :type="type"
        class="io-text-field-rounded"
        rounded filled
        :placeholder="ph"
        @submit="$emit('submit')"
        hide-details>
      <template slot="append" v-if="active">
        <v-btn
            @click.stop="$emit('submit')"
            fab x-small
            elevation="14"
            dark :color="`var(--color-${color})`">
          <v-icon>io-ico-right</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
export default {
  name: "io-text-field-submit",
  components: {},
  data(){
    return{
      loop:null,
      ph:""
    }
  },
  props: {
    type:{
      type:String,
      default:"text"
    },
    placeholder:{
      type:[String,Array]
    },
    value:{
      type:String
    },
    color:{
      type:String,
      default:"green"
    },
    disabled:{
      type:Boolean
    },
    active:{
      type:Boolean,
      default:true
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch:{
    placeholder(){
      this.placeholderLoop();
    }
  },
  methods:{
    placeholderLoop(){
      if(Array.isArray(this.placeholder)){
        let me=this;
        let i=0;
        me.ph=me.placeholder[i];
        if(me.loop){
          clearInterval(me.loop);
        }
        me.loop=setInterval(function(){
          i++;
          if(i>=me.placeholder.length){
            i=0;
          }
          me.ph=me.placeholder[i];
        },1500);
      }else{
        this.ph=this.placeholder
      }
    }
  },
  mounted(){
    this.placeholderLoop();
  },
  beforeDestroy() {
    if(this.loop){
      clearInterval(this.loop);
    }
  }
}
</script>

<style lang="less">
.io-text-field-submit{
  &.inactive{
    .io-text-field-rounded{
      .v-input__slot{
        background-color: transparent !important;
      }

    }
  }
  .io-text-field-rounded{
    .v-input__append-inner{
      margin-top: auto !important;
      margin-bottom: auto !important;
      margin-right: -6px !important;
    }
  }
}

</style>
<template>
  <div class="burger"
       :class="{
          active:active,
          arrow:arrow
        }"
  >
    <div class="a"></div>
    <div class="b"></div>
    <div class="c"></div>
  </div>
</template>

<script>
export default {
  name: "burger",
  props:{
    active:{
      type:Boolean
    },
    arrow:{
      type:Boolean
    }
  }
}
</script>

<style lang="less">
.burger {
  //overflow: hidden;
  --size:30px;
  --weight:1px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  //outline: 1px solid rgba(255, 0, 0, 0.4);
  >*{
    transition: all .5s ease-in-out;
    position: absolute;
    background: currentColor;
    width: var(--size);
    height: var(--weight);

  }
  .a{
    top: 15%;
    transform-origin: center;
  }
  .b{
    top: 50%;
  }
  .c{
    top: calc(85% - var(--weight));
    transform-origin: center;
  }
  &.active{
    .a{
      left: 0%;
      top: 50%;
      transform: rotate(45deg) ;
    }
    .b{
      transform: translateX(100%) scaleX(0);
    }
    .c{
      left: 0%;
      top: 50%;
      transform: rotate(-45deg) ;
    }

    &.arrow{
      //overflow: visible;
      //outline: 1px solid red;
      .a{
        transform-origin: top right;
        left: 30%;
        width: 70%;
      }
      .b{
        transform: translateX(0 ) scaleX(1);
      }
      .c{
        transform-origin: top right;
        transform: rotate(-45deg) ;
        top: 50%;
        left: 30%;
        width: 70%;
      }
    }

  }
}



</style>
<template>
  <v-container>
    <h3 class="text-h3 my-5">Des fichiers & des couleurs</h3>

    <h3 class="text-h4">Types de fichiers</h3>
    <io-spacing/>
    <v-row>
      <v-col
          cols="3"
          v-for="(mimeExt) of $utils.files.mimes"
          :key="mimeExt.ext"
          class="d-flex align-center">
        <io-dot
            class="mr-2"
            :color="$utils.colors.mimeColor(mimeExt.mime,mimeExt.ext)"/>
        <io-text-log>
          {{mimeExt.mime}} - .{{mimeExt.ext}} / <b>{{$utils.files.fileFamily(mimeExt.mime,mimeExt.ext)}}</b>
        </io-text-log>
      </v-col>
    </v-row>

    <io-spacing/>
    <h3 class="text-h3">Upload & downloads</h3>
    <io-spacing/>
    <input type="file" multiple @change="pickFiles">
    <io-spacing/>
    <div v-for="(f,i) of files" :key="f._meta.id+'-'+i">
        <io-card>
          <kbd>{{ f._meta.name }} {{f.isUpload?'UP':'DWD'}}</kbd>
          <io-spacing/>
          <file-alt-line :file="f"/>
        </io-card>
      <io-spacing/>
    </div>

  </v-container>

</template>

<script>
import FileMeta from "@/services/FileMeta";
import FileAlt from "@/services/FileAlt";
import IoSpacing from "@/components/atoms/io-spacing";
import IoDot from "@/components/atoms/io-dot";
import IoCard from "@/components/atoms/io-card";
import IoTextLabel from "@/components/atoms/io-text-label";
import IoFileProgress from "@/components/file-items/io-file-progress";
import FileAltLine from "@/components/file-items/file-alt-line";
import IoFileMenuCard from "@/components/file-items/io-file-menu-card";

// eslint-disable-next-line no-unused-vars
import {gsap} from "gsap"
import IoTextCode from "@/components/atoms/io-text-code";
import IoTextLog from "@/components/atoms/io-text-log";

export default {
  name: "sg-files",
  // eslint-disable-next-line vue/no-unused-components
  components: {IoTextLog, IoTextCode, IoFileMenuCard, FileAltLine, IoFileProgress, IoTextLabel, IoCard, IoDot, IoSpacing},
  data() {
    return {
      /**
       * @type {FileAlt[]}
       */
      files: []
    }
  },
  mounted(){
   //this.files.push({"_meta":{"id":"acad05b3-63bf-4f43-a9c1-9df347738210","name":"andrea.png","size":40778,"type":"image/png","extension":"png","totalSize":0,"chunkNumber":0,"chunkFileNumber":0},"file":null,"progressPercentage":-1,"progressBytes":-1,"isUpload":true,"_rtcTransfer":null,"isPaused":false,"canResume":false,"fairswapFileSender":{"fairswap":{"hashFileBlocks":[],"hashEncryptedBlocks":[],"treeFileBlocks":null,"treeEncryptedBlocks":null,"nbFileBlocks":0,"nbBlocks":0,"nbBlocks2":0,"flagReadFileBlocks":true},"key":"69b5d21cd7e50cabafb43763db7821ac","encodedChunks":[],"size":0,"encodedTreeBlocks":[],"encodedTreeBlocksIndex":0,"chunkSize":51200},"fairswapFileReceiver":{"fairswap":{"hashFileBlocks":[],"hashEncryptedBlocks":[],"treeFileBlocks":null,"treeEncryptedBlocks":null,"nbFileBlocks":0,"nbBlocks":0,"nbBlocks2":0,"flagReadFileBlocks":true},"chunks":[],"encodedChunks":[],"r":null,"size":0,"totalChunkNumber":0,"bytesReceived":0,"chunkReceived":0,"fileChunkNumber":0},"_status":"RAW","_pausedStatus":null,"logs":[]})

    let tests=[
      {
        name:"full test download.png",
        size:500,
        type:"image/png",
        up:false,
        status:FileAlt.STATUS_RAW,
        animate:true
      },
      {
        name:"full test upload.png",
        size:500,
        type:"image/png",
        up:true,
        status:FileAlt.STATUS_RAW,
        animate:true
      },
      {
        name:"fast test download.png",
        size:500,
        type:"image/png",
        up:false,
        status:FileAlt.STATUS_RAW,
        animate:true,
        animateFast:true
      },
      {
        name:"fast test upload.png",
        size:500,
        type:"image/png",
        up:true,
        status:FileAlt.STATUS_RAW,
        animate:true,
        animateFast:true
      },
      {
        name:"test.png",
        size:500,
        type:"image/png",
        up:false,
        status:FileAlt.STATUS_RAW
      },{
        name:"test.png",
        size:20 *1024 +50,
        type:"image/png",
        up:true,
        status:FileAlt.STATUS_RAW
      },

      {
        name:"test.png",
        size:4070000,
        type:"image/png",
        up:false,
        status:FileAlt.STATUS_ENCODED
      },{
        name:"test.png",
        size:4077800,
        type:"image/png",
        up:true,
        status:FileAlt.STATUS_ENCODED
      },

      {
        name:"décodage-dwd long text.png",
        size:4070000,
        type:"image/png",
        up:false,
        status:FileAlt.STATUS_TRANSFERRED
      },{
        name:"décodage-up.png",
        size:4077800,
        type:"image/png",
        up:true,
        status:FileAlt.STATUS_KEY_RECEIVED
      },

      {
        name:"almost-win-dwd.png",
        size:4070000,
        type:"image/png",
        up:false,
        status:FileAlt.STATUS_DECODED
      },{
        name:"almost-win-up.png",
        size:4077800,
        type:"image/png",
        up:true,
        status:FileAlt.STATUS_DECODED
      },

      {
        name:"win-dwd.png",
        size:4070000,
        type:"image/png",
        up:false,
        status:FileAlt.STATUS_DOWNLOADED
      },{
        name:"win-up.png",
        size:4077800,
        type:"image/png",
        up:true,
        status:FileAlt.STATUS_DOWNLOADED
      },
    ]

    for(let t of tests){
      let meta = new FileMeta();
      meta.initFromFile(t);
      let fileAlt = new FileAlt();
      fileAlt.meta = meta;
      fileAlt.isUpload=t.up;
      fileAlt.status=t.status;
      if(fileAlt.step===FileAlt.STEP_TRANFERRING){
        gsap.to(fileAlt,{
          duration:5,
          progressPercentage:100,
          yoyo:true,
          repeat:-1,
          snap: "progressPercentage",
          ease:"none"
        })
      }
      if(t.animate){
        let duration=5;
        if(t.animateFast){
          duration=0.1;
        }
        let tl = gsap.timeline({repeat: -1, repeatDelay: 25});
        tl.set(fileAlt, {status:FileAlt.STATUS_RAW});
        tl.set(fileAlt, {status:FileAlt.STATUS_OFFERED},'+='+duration);
        tl.fromTo(fileAlt, {progressPercentage:0},{
          ease:"none",
          progressPercentage:100,
          duration:duration,
          snap: "progressPercentage"
        });
        tl.set(fileAlt, {status:FileAlt.STATUS_KEY_RECEIVED},'+=0.1');
        tl.set(fileAlt, {status:FileAlt.STATUS_DECODED},'+='+duration);
        tl.set(fileAlt, {status:FileAlt.STATUS_DOWNLOADED},'+='+duration);

      }


      this.files.push(fileAlt)

    }


  },
  methods: {
    pickFiles(e) {
      console.log(e)
      let files = e.target.files;
      for (let f of files) {
        let meta = new FileMeta();
        meta.initFromFile(f);
        let fileAlt = new FileAlt();
        fileAlt.meta = meta;
        fileAlt.isUpload = Math.random() > 0.5;
        this.files.push(fileAlt)
        console.log(JSON.stringify(fileAlt))
      }

    }
  }
}
</script>

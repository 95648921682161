var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-h3 my-5"},[_vm._v("Pour dire que c'est sécurisé, cripté, hashé etc...")]),_c('sg-divider',[_vm._v("io-motion-text-encoding")]),_c('io-text-code-lg',[_c('io-motion-text-encoding')],1),_c('io-text-code-lg',[_c('io-motion-text-encoding',{attrs:{"possible-chars":"ABab","min-length":"4","max-length":"8"}})],1),_c('io-text-code-lg',[_c('io-motion-text-encoding',{attrs:{"possible-chars":"1234567890","min-length":"4","max-length":"4"}})],1),_c('io-text-code-lg',[_c('io-motion-text-encoding',{attrs:{"text":_vm.randomName,"min-length":"5","max-length":"10"}})],1),_c('io-text-log',[_c('io-motion-text-encoding',{attrs:{"text":_vm.randomName,"min-length":"5","max-length":"10"}})],1),_c('io-text-title2',[_c('io-motion-text-encoding',{attrs:{"text":_vm.randomName,"min-length":"5","max-length":"10"}})],1),_c('h3',{staticClass:"text-h3 my-5"},[_vm._v("Pour expliquer...")]),_c('sg-divider',[_vm._v("io-motion-text-slide")]),_c('io-text-title',[_c('div',[_vm._v("Inatysco permet d'échanger des fichiers")]),_c('io-motion-text-slide',{attrs:{"items":[
              'en toute sécurité',
              'en toute simplicité',
              'sans intermédiaire',
              'gratuitement',
              'tout simplement',
              'avec vos amis',
              'avec vos collègues' ]}})],1),_c('io-spacing'),_c('io-text-code-lg',[_c('div',[_vm._v("Inatysco permet d'échanger des fichiers")]),_c('io-motion-text-slide',{attrs:{"items":[
              'en toute sécurité',
              'en toute simplicité',
              'sans intermédiaire',
              'gratuitement',
              'tout simplement',
              'avec vos amis' ]}})],1),_c('io-spacing'),_c('io-text-title2',[_c('div',[_vm._v("Inatysco permet d'échanger des fichiers")]),_c('io-motion-text-slide',{attrs:{"items":[
              'en toute sécurité',
              'en toute simplicité',
              'sans intermédiaire' ]}})],1),_c('h3',{staticClass:"text-h3 my-5"},[_vm._v("Pour inciter à l'action...")]),_c('code',[_vm._v("TODO animation texte fade...")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
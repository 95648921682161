import {FairswapFileReceiver, FairswapFileSender} from "@inatysco/fairswap.js";
import FileAltLog from "@/services/FileAltLog";

/**
 * Class representing a File in this application
 * It contains metadata of the file, the file and transfer information
 */
export default class FileAlt {

    constructor() {
        /**
         * Metadata of the file
         * @type {FileMeta}
         */
        this._meta = null;
        /**
         * Native file object
         * @type {File}
         */
        this.file = null;
        /**
         * Transfer progress in percentage of the file
         * @type {number}
         */
        this.progressPercentage = -1;
        /**
         * Same as progressPercentage rounded to integer
         * @type {number}
         */
        this.progressPercentageRound = -1;
        /**
         * Transfer progress in bytes of the file
         * @type {number}
         */
        this.progressBytes = -1;
        /**
         * True is this file is sent by "me"
         * @type {boolean}
         */
        this.isUpload = false;
        /**
         * Simple peer file transfer object
         * @type {any}
         */
        this._rtcTransfer = null;

        /**
         * True when the transfer is paused
         * @type {boolean}
         */
        this.isPaused = false;

        /**
         *
         * @type {boolean}
         */
        this.canResume = false;

        /**
         *
         * @type {FairswapFileSender}
         */
        this.fairswapFileSender = new FairswapFileSender();

        /**
         *
         * @type {FairswapFileReceiver}
         */
        this.fairswapFileReceiver = new FairswapFileReceiver();

        /**
         *
         * @type {string}
         */
        this._status = FileAlt.STATUS_RAW;

        this._pausedStatus = null;
        /**
         * Logs TODO this is filled by vue component, but should be by models directly
         * @private
         * @type {FileAltLog[]}
         */
        this._logs=[];
    }

    /**
     * Add a log line
     * @param {String} message
     */
    addLog(message){
        this._logs.push(
            new FileAltLog(message)
        )
    }

    /**
     * The transfer logs
     * @returns {FileAltLog[]}
     */
    get logs(){
        return this._logs;
    }

    resetFairswapFileReceiver() {
        this.fairswapFileReceiver = new FairswapFileReceiver();
        this.fairswapFileReceiver.size = this.meta.totalSize;
        this.fairswapFileReceiver.totalChunkNumber = this.meta.chunkNumber;
        this.fairswapFileReceiver.fileChunkNumber = this.meta.chunkFileNumber;
    }

    /**
     *
     * @param {FileMeta} meta
     */
    set meta(meta) {
        this._meta = meta;
        this.fairswapFileReceiver.size = meta.totalSize;
        this.fairswapFileReceiver.totalChunkNumber = meta.chunkNumber;
        this.fairswapFileReceiver.fileChunkNumber = meta.chunkFileNumber;
    }

    get meta() {
        return this._meta;
    }

    set transfer(transfer) {
        this._rtcTransfer = transfer;
        this.transfer.on('progress', (percentage, receivedBytes) => {
            this.progressPercentageRound = parseInt(percentage);
            this.progressPercentage = percentage;
            this.progressBytes = receivedBytes;
        });
        this.transfer.on('pause', () => {
            this.isPaused = true;
            this.canResume = true;
        });
        this.transfer.on('paused', () => {
            this.isPaused = true;
            this.canResume = false;
        });
        this.transfer.on('resume', () => {
            this.isPaused = false;
        });
        this.transfer.on('resumed', () => {
            this.isPaused = false;
        });
    }

    get transfer() {
        return this._rtcTransfer;
    }

    pause() {
        this._pausedStatus = this.status;
        this.status = FileAlt.STATUS_PAUSED;
        this.transfer.pause();
    }

    cancel() {
        this.status = FileAlt.STATUS_CANCELED;
        this.transfer.cancel();
    }

    resume() {
        if (this._pausedStatus) {
            this.status = this._pausedStatus;
            this._pausedStatus = null;
            this.transfer.resume();
        }
    }

    statusOffered() {
        this.status = FileAlt.STATUS_OFFERED;
    }

    statusAccepted() {
        this.status = FileAlt.STATUS_ACCEPTED;
    }

    statusRaw() {
        this.status = FileAlt.STATUS_RAW;
    }

    statusEncoded() {
        this.status = FileAlt.STATUS_ENCODED;
    }

    statusTransferred() {
        this.status = FileAlt.STATUS_TRANSFERRED;
    }

    statusVerified() {
        this.status = FileAlt.STATUS_VERIFIED;
    }

    statusKeyReceived() {
        this.status = FileAlt.STATUS_KEY_RECEIVED;
    }

    statusDecoded() {
        this.status = FileAlt.STATUS_DECODED;
    }

    statusDownloaded() {
        this.status = FileAlt.STATUS_DOWNLOADED;
    }

    statusError() {
        this.status = FileAlt.STATUS_ERROR;
    }

    statusCanceled() {
        this.status = FileAlt.STATUS_CANCELED;
    }

    statusCancel() {
        this.status = FileAlt.STATUS_CANCEL;
    }

    set status (status) {
        // TODO verify exists in "enum"
        if(FileAlt.ALL_STATUS.indexOf(status)===-1){
            console.warn(`invalid STATUS '${status}'`);
        }
        this._status = status;
    }

    get status() {
        return this._status;
    }

    /**
     * According the status, the step defines the current step in the transfer process
     * @return {string}
     */
    get step(){
        switch (this.status){
            case "":
            case FileAlt.STATUS_RAW:
            case FileAlt.STATUS_ENCODED:
            case FileAlt.STATUS_OFFERED:
                return FileAlt.STEP_ENCODING;
            case FileAlt.STATUS_ACCEPTED:
            case FileAlt.STATUS_PAUSED:
                return FileAlt.STEP_TRANFERRING;
            case FileAlt.STATUS_TRANSFERRED:
            case FileAlt.STATUS_KEY_RECEIVED:
            case FileAlt.STATUS_VERIFIED:
                return FileAlt.STEP_DECODING;
            case FileAlt.STATUS_DECODED:
                return FileAlt.STEP_WAITING;
            case FileAlt.STATUS_DOWNLOADED:
                return FileAlt.STEP_DOWNLOADED;
            default:
                return this.status;
        }
    }

    /**
     * Is the provided action possible according transfer state?
     * @private
     * @param {String} action One of the ACTION_something possibilities
     * @return {boolean}
     */
    isActionPossible(action){
        switch (action){

            case FileAlt.ACTION_PAUSE:
                return !this.isPaused && [
                    FileAlt.STEP_TRANFERRING
                ].indexOf(this.step)>-1;

            case FileAlt.ACTION_RESUME:
                return this.isPaused;

            case FileAlt.ACTION_CANCEL:
                return [
                    FileAlt.STEP_TRANFERRING,
                ].indexOf(this.step)>-1;

            case FileAlt.ACTION_DOWNLOAD:
                return [
                    FileAlt.STEP_WAITING,
                    FileAlt.STEP_DOWNLOADED,
                ].indexOf(this.step)>-1;

            default:
                return false;
        }
    }

    get possibleAction(){
        return {
            PAUSE:this.isActionPossible(FileAlt.ACTION_PAUSE),
            RESUME:this.isActionPossible(FileAlt.ACTION_RESUME),
            CANCEL:this.isActionPossible(FileAlt.ACTION_CANCEL),
            DOWNLOAD:this.isActionPossible(FileAlt.ACTION_DOWNLOAD),
        }
    }
}

FileAlt.STATUS_RAW = "RAW";
FileAlt.STATUS_ENCODED = "ENCODED";
FileAlt.STATUS_OFFERED = "OFFERED";
FileAlt.STATUS_ACCEPTED = "ACCEPTED";
FileAlt.STATUS_PAUSED = "PAUSED";
FileAlt.STATUS_CANCELED = "CANCELED";
FileAlt.STATUS_CANCEL = "CANCEL";
FileAlt.STATUS_TRANSFERRED = "TRANSFERRED";
FileAlt.STATUS_VERIFIED = "VERIFIED";
FileAlt.STATUS_KEY_RECEIVED = "KEY_RECEIVED";
FileAlt.STATUS_DECODED = "DECODED";
FileAlt.STATUS_DOWNLOADED = "DOWNLOADED";
FileAlt.STATUS_ERROR = "ERROR";

FileAlt.ALL_STATUS=[
    FileAlt.STATUS_RAW,
    FileAlt.STATUS_ENCODED,
    FileAlt.STATUS_OFFERED,
    FileAlt.STATUS_ACCEPTED,
    FileAlt.STATUS_PAUSED,
    FileAlt.STATUS_CANCELED,
    FileAlt.STATUS_CANCEL,
    FileAlt.STATUS_TRANSFERRED,
    FileAlt.STATUS_VERIFIED,
    FileAlt.STATUS_KEY_RECEIVED,
    FileAlt.STATUS_DECODED,
    FileAlt.STATUS_DOWNLOADED,
    FileAlt.STATUS_ERROR
]

FileAlt.STEP_ENCODING="ENCODING";
FileAlt.STEP_TRANFERRING="TRANSFERRING";
FileAlt.STEP_DECODING="DECODING";
FileAlt.STEP_WAITING="WAITING";
FileAlt.STEP_DOWNLOADED="DOWNLOADED";
FileAlt.STEP_UNKNOWN="UNKNOWN";

FileAlt.ACTION_PAUSE="PAUSE";
FileAlt.ACTION_RESUME="RESUME";
FileAlt.ACTION_CANCEL="CANCEL";
FileAlt.ACTION_DOWNLOAD="DOWNLOAD";

<template>
<v-container class="io-room-container">
      <v-row justify="center">
        <v-col cols="12" sm="10">
          <io-tab-label-room
              :room-name="roomName"
              :connected="connected"
          />
          <io-card>
            <slot></slot>
          </io-card>
        </v-col>
      </v-row>

  <div class="users">
    <v-container>
      <v-row justify="center">
        <v-col cols="6" sm="5" class="text-center text-sm-left">
          <io-chip-player
              :user-name="userName"
              :is-connected="connected"
          />
        </v-col>
        <v-col cols="6" sm="5" class="text-center text-sm-right">
          <io-chip-player
              is-partner
              :user-name="partnerName"
              :is-connected="connected"/>
        </v-col>
      </v-row>
    </v-container>

  </div>





</v-container>
</template>

<script>
import IoTabLabelRoom from "@/components/molecules/io-tab-label-room";
import IoRoomMixin from "@/components/mixins/IoRoomMixin";
import IoCard from "@/components/atoms/io-card";
import IoChipPlayer from "@/components/molecules/io-chip-player";
export default {
  name: "io-room-container",
  components: {IoChipPlayer, IoCard, IoTabLabelRoom},
  mixins:[IoRoomMixin],
}
</script>

<style lang="less">
.io-room-container{
  position: relative;
  .users{
    pointer-events: none;
    position: absolute;
    width: 100%;
    top: 110px;
    .row{
      >*{
        --user-icon-padding:30px;
        //outline: 1px dashed red;
        //outline-offset: -2px;
        @media(min-width: @screen-sm){
          &:last-child{
            >.io-chip-player{
              transform: translateX( calc( 30%  ) );
            }
          }
          &:first-child{
            >.io-chip-player{
              transform: translateX( calc( -30% ) );
            }
          }
        }
        @media(min-width: @screen-lg){
          &:last-child{
            >.io-chip-player{
              transform: translateX( calc( 100% - var(--user-icon-padding) ) );
            }
          }
          &:first-child{
            >.io-chip-player{
              transform: translateX( calc( -100% + var(--user-icon-padding) ) );
            }
          }
        }
      }

    }
  }
  .io-tab-label-room{
    margin-left: 20px;
    @media(min-width: @screen-sm){
      margin-left: 60px;
    }
    @media(min-width: @screen-md){
      margin-left: 80px;
    }

  }
}
</style>
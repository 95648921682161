<template>
<div class="io-file-log">
  <v-icon class="icon">{{icon}}</v-icon>
  <io-text-log>

    {{time}} {{log}}

    <io-motion-text-encoding
        v-if="isEncoding || isDecoding"
        min-length="10"
        max-length="10"/>
    ({{status}})
  </io-text-log>

</div>
</template>

<script>
import FileAltMixin from "@/components/file-items/FileAltMixin";
import IoTextLog from "@/components/atoms/io-text-log";
import IoMotionTextEncoding from "@/components/atoms/motion-text/io-motion-text-encoding";
import FileAlt from "@/services/FileAlt";

export default {
  name: "io-file-log",
  components: {IoMotionTextEncoding, IoTextLog},
  mixins:[FileAltMixin],
  data(){
    return {
      time:this.$utils.date.nowTimeHHmm()
    }
  },
  props:{
    stepProxified:{
      type:String
    }
  },
  mounted() {
    this.addLog();
  },
  computed:{
    log(){
      let str=""
      if(this.isDownload){
        str= this.$t(`file-step-log.dwd.${this.stepProxified}`);
      }else{
        str= this.$t(`file-step-log.up.${this.stepProxified}`);
      }
      if(!str){
        str = "TODO "+this.status+" / "+this.stepProxified;
      }
      return str;
    },
    icon(){
      switch (true){
        case this.stepProxified===FileAlt.STEP_ENCODING:
          return "io-ico-key"
        case this.stepProxified===FileAlt.STEP_TRANFERRING && this.isUpload:
          return "io-ico-up"
        case this.stepProxified===FileAlt.STEP_TRANFERRING && this.isDownload:
          return "io-ico-down"
        case this.stepProxified===FileAlt.STEP_DECODING :
          return "io-ico-key"
        case this.stepProxified===FileAlt.STEP_WAITING :
          return "io-ico-alert"
        case this.stepProxified===FileAlt.STEP_DOWNLOADED :
          return "io-ico-check"

      }
      return "io-ico-close" //should never be displayed
    }
  },
  watch:{
    log(){
      this.addLog();
    }
  },
  methods:{
    addLog(){
      this.file.addLog(this.log);
    }
  }
}
</script>

<style lang="less">
.io-file-log{
  padding: 0 6px;
  display: flex;
  align-items: center;
  .io-text-log{
    margin: 0 5px;
    display: flex;
    align-items: center;
    .io-motion-text-encoding{
      margin: 0 5px;
    }
  }
  .icon{
    color: var(--color-grey-light);
    font-size: 16px;
  }
}
</style>
<template>
<div
    class="drop filedrop"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop">
  <div class="drop-content">
    <input id="fileInput"
           type="file"
           @change="inputChange"/>
    <div class="gfx">
      <v-icon class="icon" size="32">io-ico-download</v-icon>
      <io-text-label>{{$t("file-transfer-actions.add-file")}}</io-text-label>
    </div>
    
  </div>
</div>
</template>

<script>
import IoTextLabel from "@/components/atoms/io-text-label";
export default {
  name: "filedrop",
  components: {IoTextLabel},
  props: {
    onFile: { type: Function },
  },
  methods: {
    inputChange(event) {
      for (let file of event.target.files) {
        console.log("Sending file: " + file);
        this.onFile(file);
      }
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('drop-over')) {
        event.currentTarget.classList.remove('drop-default');
        event.currentTarget.classList.add('drop-over');
      }
    },
    //todo @david use computed classes
    dragleave(event) {
      event.currentTarget.classList.add('drop-default');
      event.currentTarget.classList.remove('drop-over');
    },
    drop(event) {
      event.preventDefault();
      for (let file of event.dataTransfer.files) {
        console.log("Sending file: " + file);
        this.onFile(file);
      }
      event.currentTarget.classList.add('drop-default');
      event.currentTarget.classList.remove('drop-over');
    }
  }
}
</script>

<style lang="less">
.filedrop{
  position: relative;
  cursor: pointer;
  &.drop-over, &:hover {
    .drop-content {
      border: 2px dashed var(--color-player1);
    }
  }
  &.drop-default {
    background-color: white;
  }
  .drop-content {
    cursor: pointer;
    .icon{
      color: inherit;
    }
    .io-text-label{
      opacity: 0.5;
    }
    height: 94px;
    border: 2px dashed var(--color-grey-medium);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    transition: border-color 0.25s;

    .gfx{
      pointer-events: none;
      display: flex;
      align-items: center;

    }
  }
  #fileInput {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}





</style>

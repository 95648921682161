<template>
  <h2 class="io-text-code"  :style="{color:textColor}">
    <slot></slot>
  </h2>

</template>

<script>
import IoTextMixin from "@/components/atoms/IoTextMixin";

export default {
  name: "io-text-code",
  mixins:[IoTextMixin],
  props:{
    color:{
      type:String,
      default:"title"
    }
  }
}
</script>

export default class Legal{
    /**
     * Handle GTU user consent, remember & download
     */
    constructor() {
        /**
         * The actual CGU version (change it when there is new)
         * @type {string}
         */
        this.gtuVersion="2021-06-04";
        /**
         * Local storage name where we store the accepted version
         * @type {string}
         */
        this.lsName="gtuVersion"
        /**
         * Are terms accepted or not?
         * @type {boolean}
         * @private
         */
        this._accepted=localStorage.getItem(this.lsName)===this.gtuVersion;
    }

    get accepeted(){
        return this._accepted;
    }
    set accepted(value){
        if(value){
            localStorage.setItem(this.lsName,this.gtuVersion);
            this._accepted=true;
        }else{
            this._accepted=false
        }

    }

    /**
     * @todo Let user download the GTU file
     */
    download(){
        alert("todo download CGU");
    }

}
<template>
<div>
  <sg-divider>io-btn</sg-divider>
  <v-row class="text-center">
    <v-col cols="6">
      <io-btn>default button</io-btn>
    </v-col>
    <v-col cols="6">
      <io-btn small>small button</io-btn>
    </v-col>
    <v-col cols="6">
      <io-btn>
        slot="icon"
        <template slot="icon">
          <v-icon size="30">io-ico-download</v-icon>
        </template>
      </io-btn>
    </v-col>
    <v-col cols="6">
      <io-btn small>
        slot="icon"
        <template slot="icon">
          <v-icon size="30">io-ico-download</v-icon>
        </template>
      </io-btn>
    </v-col>
    <v-col cols="6">

    </v-col>
    <v-col cols="6">

    </v-col>
  </v-row>

  <sg-divider>io-btn-text</sg-divider>
  <v-alert color="#333" dark elevation="5" class="mb-10">Afin de respecter les interlignages le bouton texte dispose de la propriété <code>small</code> au même titre que les boutons classiques</v-alert>
  <v-row>
    <v-col cols="6" class="text-right">
      <io-btn>Bouton normal</io-btn>
    </v-col>
    <v-col  cols="6">
      <io-btn-text>bouton texte</io-btn-text>
    </v-col>
    <v-col  cols="6" class="text-right">
      <io-btn small>Bouton small</io-btn>
    </v-col>
    <v-col  cols="6">
      <io-btn-text small>bouton texte small</io-btn-text>
    </v-col>
  </v-row>

  <io-spacing/>


  <sg-card/>

  <io-spacing/>

</div>
</template>

<script>
import SgDivider from "@/style-guide/sg-divider";
import IoBtn from "@/components/atoms/io-btn";
import IoSpacing from "@/components/atoms/io-spacing";
import SgCard from "@/style-guide/sg-card";
import IoBtnText from "@/components/atoms/io-btn-text";
export default {
  name: "sg-atoms",
  components: {IoBtnText, SgCard, IoSpacing, IoBtn, SgDivider}
}
</script>

<style scoped>

</style>
<template>
<div class="io-log-line">
  <io-text-log color="var(--color-grey-blue-light)">
    {{$utils.date.timeHHmm(date)}}
  </io-text-log>
  <io-text-regular color="var(--color-daaark)">
    {{message}}
  </io-text-regular>
</div>
</template>

<script>
import IoTextLog from "@/components/atoms/io-text-log";
import IoTextRegular from "@/components/atoms/io-text-regular";
export default {
  name: "io-log-line",
  components: {IoTextRegular, IoTextLog},
  props:{
    message:{
      type:String
    },
    date:{
      type:Date
    }
  }
}
</script>

<style lang="less">
.io-log-line{
  display: flex;
  align-items: baseline;
  >*{
    margin: 0 10px 0 0;
    line-height: 1;
    font-size: 11px !important;
  }
}
</style>
<template>
<div class="room-page">
  <io-room-container
      v-if="$ui.fileTransferService && $ui.messageService"
      :connected="$services.rtcConnection.isConnected"
      :room-name="$services.rtcConnection.roomId"
      :user-name="$services.rtcConnection.userName"
      :partner-name="$services.rtcConnection.partnerName"
  >

    <filedrop
        v-if="$services.rtcConnection.isConnected"
        :onFile="sendFile"/>

      <io-text-regular
        v-else
        class="text-center"
      >
        {{$t("connection.errors.LOST")}}
      </io-text-regular>


    <file-alt-line
        v-for="item in files"
        :key="item.meta.id"
        :file="item"
        class="my-5"
    />

  </io-room-container>

  <div>
    <io-icon-elevate
        class="chat-btn"
        @click.native="$ui.chatOpen=true;"
        v-if="!$ui.chatOpen">
      <v-icon large>io-ico-chat</v-icon>
      <io-dot
          v-if="unreadMessageCount"
          class="io-motion-blink hard"
          size="20px"
          color="var(--color-player2)">
        <io-text-log color="var(--color-player1)">{{unreadMessageCount}}</io-text-log>
      </io-dot>
    </io-icon-elevate>
    <transition name="flash">
    <io-chat-window
        v-if="$ui.chatOpen"
        :user-name="$services.rtcConnection.userName"
        :partner-name="$services.rtcConnection.partnerName"
        :last-message="$ui.messageService.messages.length"
        @submit="sendMessage"
    >
      <io-chat-line
          v-for="(message) in $ui.messageService.messages"
          :key="message.timestamp"
          :is-recipient="message.user !== $services.rtcConnection.userName"
          :date="new Date(message.timestamp)"
      >
        {{ message.content }}
      </io-chat-line>
    </io-chat-window>
    </transition>
  </div>
</div>
</template>

<script>
import Filedrop from "../components/filedrop/filedrop";
import FileAltLine from "../components/file-items/file-alt-line";
import IoRoomContainer from "@/components/organisms/io-room-container";
import IoChatWindow from "@/components/organisms/io-chat-window";
import IoChatLine from "@/components/molecules/io-chat-line";
import IoIconElevate from "@/components/atoms/io-icon-elevate";
import IoDot from "@/components/atoms/io-dot";
import IoTextLog from "@/components/atoms/io-text-log";
import IoTextRegular from "@/components/atoms/io-text-regular";



export default {
  name: "room",
  components: {
    IoTextRegular,
    IoTextLog, IoDot, IoIconElevate, IoChatLine, IoChatWindow, IoRoomContainer, FileAltLine, Filedrop},
  data: function () {
    return {
      readMessagesCount:0,
      newMessage: "",
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    /**
     * file list (reversed)
     * @return {FileAlt[]}
     */
    files(){
      return this.$ui.fileTransferService.files.slice().reverse();
    },
    /**
     * Number of unread messages
     * @return {number}
     */
    unreadMessageCount(){
      if(this.$ui.chatOpen){
        return 0;
      }
      return this.messagesCount-this.readMessagesCount;
    },
    /**
     * Messages count
     * @return {number}
     */
    messagesCount(){
      if(!this.$ui.messageService){
        return 0;
      }
      return this.$ui.messageService.messages.length;
    },
    /**
     * Is the room connected ?
     * @return {Boolean}
     */
    isRoomReady() {
      return this.$services.rtcConnection.isConnected
    },
    chatOpen(){
      return this.$ui.chatOpen
    }
  },
  watch: {
    /*
    isRoomReady() {
      if (!this.isRoomReady) {
        this.$router.push({name:'home'});
      }
    },
    */
    chatOpen:function(){
      console.log("open/close")
      //mark messages as read on open / close chat window
      this.readMessagesCount=this.messagesCount;
    },
    unreadMessageCount(value){
      if(value){
        this.$ui.setNotifyIcon(true);
      }else{
        this.$ui.setNotifyIcon(false);
      }
    }
  },
  methods: {
    init() {

      if (!this.isRoomReady) {
        this.$router.push({name:'home'});
      }else{
        this.$ui.initRoom();
      }
    },
    sendFile(file) {
      this.$ui.fileTransferService.sendFile(file);
    },
    sendMessage(newMessage) {
      this.$ui.messageService.sendMessage(newMessage);
      this.newMessage = "";
    }
  }
}
</script>

<style lang="less">
.room-page{
  .io-room-container{
    .users{
      position: fixed;
      left: 0;
      width: calc( 100vw - 70px);
      bottom: 4px;
      top: auto;
      @media(min-width: @screen-sm){
        width: 100%;
        top: 180px;
        bottom: auto;
      }
    }
  }
  .chat-btn{
    cursor: pointer;
    --size:60px;
    transform: none;
    @media(min-width: @screen-sm){
      --size:80px;
      transform: scale(1.2);
    }
    .io-dot{
      position: absolute;
      top: 3px;
      right: 3px;
      box-shadow: var(--io-elevation-inner);
    }
  }
  .chat-btn,.io-chat-window{
    position: fixed;
    z-index: var(--z-chat);
    bottom: 10px;
    right: 10px;
    margin: 0;
    left: auto;
    @media(min-width: @screen-sm){
      bottom: 30px;
      right: 30px;
    }
  }
}

</style>

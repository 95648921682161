<template>
<div class="io-spacing" :class="size"/>
</template>

<script>
export default {
  name: "io-spacing",
  props:{
    tiny:{
      type:Boolean,
      default:false
    },
    small:{
      type:Boolean,
      default:false
    },
    large:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    size(){
      switch (true){
        case this.tiny:
          return "tiny"
        case this.small:
          return "small"
        case this.large:
          return "large"
      }
      return "";
    }
  }
}
</script>

<style lang="less">
.io-spacing{
  flex-shrink: 0;
  min-width: 4px;
  //background-color: #6AE4BB; debug
  --tiny:6px;
  --small:8px;
  --normal:16px;
  --large:32px;
  @media(min-width: @screen-sm){
    --tiny:8px;
    --small:16px;
    --normal:32px;
    --large:54px;
  }
  @media(min-width: @screen-lg){
    --tiny:12px;
    --small:24px;
    --normal:44px;
    --large:64px;
  }
  height: var(--normal);
  &.tiny{
    height: var(--tiny);
    min-height: var(--tiny);
  }
  &.small{
    height: var(--small);
  }
  &.large{
    height: var(--large);
  }
}
</style>
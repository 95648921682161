<template>
  <div>
    <v-app @copy.native="copyToClipBoard">
      <io-app-drawer/>
      <io-app-bar/>
      <v-main id="main" >
        <io-background/>
        <div class="content">
          <div v-if="ready">
            <transition name="fade" mode="out-in">
              <!-- main content here -->
              <router-view/>
            </transition>
          </div>

        </div>
        <io-dialog-legal
            :visible="!$ui.legal.accepeted"
            @accept="$ui.legal.accepted=true"
            @download="$ui.legal.download()"
        />
        <v-snackbar
            v-model="$ui.showTemporaryMessage"
            :timeout="2000"
            elevation="10"
            rounded="pill"
            bottom
        >
          <div class="text-center">{{$ui.temporaryMessage}}</div>
        </v-snackbar>

      </v-main>
      <v-footer color="transparent" app absolute>
        <io-text-log>
          version <io-motion-text-encoding
            class="d-inline-block"
            possible-chars="0123456709."
            max-length="5"
            min-length="5"
            :text="$ui.about.version"
        />
        </io-text-log>
      </v-footer>

    </v-app>
  </div>

</template>

<script>

import IoAppBar from "@/layout/io-app-bar";
import IoAppDrawer from "@/layout/io-app-drawer";
import IoDialogLegal from "@/components/organisms/io-dialog-legal";
import IoBackground from "@/components/atoms/io-background";
import IoTextLog from "@/components/atoms/io-text-log";
import IoMotionTextEncoding from "@/components/atoms/motion-text/io-motion-text-encoding";


export default {
  name: 'App',
  components: {
    IoMotionTextEncoding,
    IoTextLog,
    IoBackground,
    IoDialogLegal,
    IoAppDrawer,
    IoAppBar,
  },
  data: () => ({
    ready:false,
    previewOpacity:0.5,
    debug:true,
  }),
  mounted() {
    let me=this;
    document.title="Inatysco";
    setTimeout(function()
      {
        me.ready=true
      },1000
    );
  },
  watch: {
    '$route' (to) {
      console.log("change route",to);
    }
  },
  methods:{
    test(msg){
      alert(msg);
    },
    /**
     * Force plain text globally on user copy
     */
    copyToClipBoard(){
      let text = "";
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type !== "Control") {
        text = document.selection.createRange().text;
      }
      this.$ui.copyToClipboard(text);
    }
  }
};
</script>
<style lang="less">
@import "components/atoms/scroll-skin";
@import "style-guide/all";
body,html{

  #app{
    #main{
      .v-main__wrap{
        .io-background{
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .content{
          position: relative;
          .page{
            min-height: calc( 100vh - 90px);
          }
        }
      }
    }
  }





}
</style>

<template>
<div class="io-text-log"  :style="{color:textColor}">
  <slot></slot>
</div>
</template>

<script>
import IoTextMixin from "@/components/atoms/IoTextMixin";
export default {
  name: "io-text-log",
  mixins:[IoTextMixin],
  props:{
    color:{
      type:String,
      default:"log"
    }
  }
}
</script>

<style scoped>

</style>
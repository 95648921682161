export default class FileAltLog{
    /**
     * A file transfer log
     * @param {String} message The message
     */
    constructor(message) {
        /**
         * When was it created ?
         * @type {Date}
         */
        this.date=new Date();
        /**
         * The message
         * @type {String}
         */
        this.message=message;
    }
    toString(){
        return window.$utils.date.humanFullDate(this.date)+" - "+this.message;
    }
}
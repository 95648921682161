<template>
<v-app-bar
    app
    elevation="0"
    color="transparent"
    style="z-index: var(--z-nav-bar)"
    class="io-app-bar"
>
  <v-spacer/>
  <!-- btn group to change language...
  <v-btn-toggle
      color="var(--color-green)"
      class="mr-5"
      rounded
      v-model="$locales.currentLocaleCode">
    <v-btn small v-for="lang of $locales.locales"
           :key="lang.code"
           :value="lang.code"
    >
      {{lang.code}}
    </v-btn>
  </v-btn-toggle>
  -->
  <burger @click.native="$ui.mainNavOpen=!$ui.mainNavOpen"
          :active="$ui.mainNavOpen"
          arrow
  />
</v-app-bar>
</template>

<script>
import Burger from "@/components/atoms/burger";
export default {
  name: "ntyco-app-bar",
  components: {Burger}
}
</script>

<style lang="less">
.io-app-bar{
  pointer-events: none;
  .burger{
    pointer-events: auto;
  }
}
</style>
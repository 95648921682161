<template>
<h3 class="io-text-label"  :style="{color:textColor}">
  <slot/>
</h3>
</template>

<script>
import IoTextMixin from "@/components/atoms/IoTextMixin";
export default {
  name: "io-text-label",
  mixins:[IoTextMixin],
  props:{
    color:{
      type:String,
      default:"label"
    }
  }
}
</script>

<style scoped>

</style>
/**
 * Global user interactions ans preferences are stored and managed here
 */
import Legal from "@/services/Legal";
import FileTransferService from "@/services/FileTransferService";
import MessageService from "@/services/MessageService";
import copy from 'copy-to-clipboard';
/* global __static */
export default class Ui{

    constructor() {
        /**
         * me = this out of scope
         * @type {Ui}
         */
        let me=this;
        /**
         * Is the main nav drawer open or not ?
         * @type {boolean}
         */
        this.mainNavOpen=false;
        /**
         * Is the chat open or not ?
         * @type {boolean}
         */
        this.chatOpen=false;
        /**
         * Are we in Browser or Electron?
         * @type {boolean}
         */
        this.isElectron= (window && window.process && window.process.type) !== undefined;
        console.log("is electron?",this.isElectron);
        /**
         *
         * @type {boolean}
         * @private
         */
        this._footerVisible=true;

        /**
         * App info such as name, version, etc...
         * @type {{name: string, version: string, description: string}}
         */
        this.about=require("../../package.json");

        /**
         * In electron this will manage auto-updates
         * @type {ReleaseUpdater}
         */
        this.releaseUpdater= {};
        if(this.isElectron){
            this.releaseUpdater = require("./release-updater/release-updater-electron-only").releaseUpdater;
        }
        /**
         * CGU accepted or not ?
         * @type {Legal}
         */
        this.legal=new Legal();

        //hide footer after 10 secs
        setTimeout(function(){
            me._footerVisible=false
        },10*1000);

        this._temporaryMessage="";
        this.showTemporaryMessage=false;
        /**
         * TODO  @david clean this ugly code
         * hack to create or conect to room after setting user name
         * @type {string}
         */
        this.tempRoomId="";
        /**
         *
         * @type {null|FileTransferService}
         */
        this.fileTransferService=null;
        /**
         *
         * @type {null|MessageService}
         */
        this.messageService=null;

        setInterval(function(){
            me.setAppIcon(Math.random()>0.5);
        },10000)

        this.electronWindow=null;
        if(this.isElectron){
            this.electronWindow=require('electron').remote.getCurrentWindow();
        }

        /**
         * utility methods to load files according context (electron vs browser)
         */
        this.path={
            publicVideo:function(file){
                if(me.isElectron){
                    return `local-video://${file}`
                }else {
                    return file;
                }
            },
            publicImage:function(file){
                if(me.isElectron){
                    return __static+"/"+file
                }else {
                    return file;
                }
            }
        }
        /**
         * Some api icons variables
         * @private
         */
        this.icon={
            blinkInterval:null,
            imagePath:{
                normal:"icon.png",
                notify:"icon-notif.png",
            }
        }

        //init icon
        this.setNotifyIcon(false);


    }

    /**
     * To open / close dev console
     * @param open
     */
    openDevConsole(open){
            if(this.isElectron){
                let win =require('electron').remote.getCurrentWindow();
                if(open){
                    win.webContents.openDevTools();
                }else{
                    win.webContents.closeDevTools();
                }
            }
    }

    initRoom(){
        this.fileTransferService=new FileTransferService(
            window.$services.rtcConnection.peer,
            window.$services.rtcConnection.partnerName
        )
        this.messageService=new MessageService(
            window.$services.rtcConnection.peer,
            window.$services.rtcConnection.partnerName
        );
    }

    get temporaryMessage() {
        return this._temporaryMessage;
    }
    set temporaryMessage(value) {
        this._temporaryMessage = value;
        if(value){
            this.showTemporaryMessage=true;
        }
    }

    /**
     * raw alert
     */
    alert(msg){
        alert(msg);
    }

    /**
     * raw console.warn
     */
    warn(msg){
        console.warn(msg);
    }

    /**
     * Copy to clipboard forcing text/plain
     * @param text
     */
    copyToClipboard(text){
        //delay to overwrite user native copy action
        setTimeout(function (){
            copy(text,{format:'text/plain'});
        },200);
    }

    /**
     * @private
     * @param {string} file located in public directory
     */
    setAppIcon(file){
        if(this.isElectron){
            let image = require('electron').nativeImage.createFromPath(this.path.publicImage(file))
            this.electronWindow.setIcon(image)
        }else{
            let $favicon=document.querySelector("head link[rel='icon']");
            $favicon.setAttribute("href",this.path.publicImage(file))
        }
    }

    /**
     * Blink (or not) app icon
     * on browser will change the favicon
     * @param {boolean} notify if true will blink
     */
    setNotifyIcon(notify=false){
        let me=this;
        if(this.icon.blinkInterval){
            clearInterval(this.icon.blinkInterval)
        }
        if(notify){
            let likeThis=true;
            this.icon.blinkInterval=setInterval(
                function()
                {
                    if(likeThis){
                        me.setAppIcon(me.icon.imagePath.notify)
                    }else{
                        me.setAppIcon(me.icon.imagePath.normal)
                    }
                    likeThis=!likeThis;
                },500)

        }else{
            this.setAppIcon(this.icon.imagePath.normal)
        }
    }

    /**
     * Close the RTC connection and back to home
     */
    closeConnection(){
        this.resetRoom();
        window.$router.push({name:"home"});
        window.location.reload(); //TODO remove this hard reload and close RTC connection in a clean way
    }
    /**
     * Connect to room
     * @param {string|null} roomId if null will create a new room else will try to create a new one
     * @param {function} onSuccess Success callback
     * @param {function} onError Error callback. Error Code is returned as argument in the callback
     */
    connect(roomId,onSuccess,onError){
        console.log("connect...")
        let me=this;
        me.resetRoom();
        let killLoop=function(){
            if(me._connectInterval){
                clearInterval(me._connectInterval);
            }
        }
        let cbSuccess=function(){
            console.log("connect success")
            killLoop();
            onSuccess();
        }
        let cbError=function(error){
            console.log("connect error")
            killLoop();
            onError(error);
        }
        if(roomId){
            console.log("connect to existing...",roomId)
            // existing room
            let me=this;
            let tries=0;
            window.$services.rtcConnection.connectToRoom(roomId);
            killLoop();
            me._connectInterval= setInterval(function(){
                if(window.$services.rtcConnection.isConnected){
                    cbSuccess();
                }else{
                    if(tries++ > 5){
                        cbError("INVALID_ROOM_ID");
                    }
                }
            },1000)
        }else{
            console.log("connect to newone...")
            // create new room
            window.$services.rtcConnection.createRoom(() =>
                {
                    if (window.$services.rtcConnection.roomId) {
                        cbSuccess();
                    }else{
                        cbError("TIME_OUT");
                    }
                }
            );
        }
    }

    /**
     * reset the rtcConnection
     */
    resetRoom(){
        window.$services.rtcConnection.isConnected = false;
        this.chatOpen=false;
        if(this._connectInterval){
            clearInterval(this._connectInterval);
        }
    }


}

<template>
<div class="io-file-dwd-btn"
     @click.stop="download"
     :class="{
       'waiting':isWaiting
     }"
     :style="{
       'background-color': bgColor,
       'border-color': bdrColor
     }">
      <v-icon :color="fgColor" size="32px">
        {{icon}}
      </v-icon>
</div>
</template>

<script>
import FileAltMixin from "@/components/file-items/FileAltMixin";

export default {
  name: "io-file-dwd-btn",
  components: {},
  mixins:[FileAltMixin],
  data: () => ({
    showMenu: false,
  }),
  computed:{
    bdrColor(){
      return this.isDownloaded?'this.colorId':'transparent';
    },
    fgColor(){
      return this.isDownloaded?this.colorId:'#fff';
    },
    bgColor(){
      return this.isDownloaded?'#fff':this.colorId;
    },
    icon(){
      return this.isDownloaded?'io-ico-folder':'io-ico-download';
    }
  }
}
</script>

<style lang="less">
.io-file-dwd-btn{
  &.waiting{
    box-shadow: var(--io-elevation-helicopter);
  }
  border-radius: 12px;
  --size:56px;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  transition: all 0.2s;
  @media(hover:hover){
    &:hover{
      transform: scale(0.95);
    }
  }

}
</style>
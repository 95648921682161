<template>
<div class="page page-home d-flex flex-column justify-center align-center">
  <v-spacer/>
  <v-container class="text-center">
    <io-text-title class="text-center" style="overflow: hidden">
      <div>{{$t('why-inatysco.intro')}}</div>
      <io-motion-text-slide
          :items="$t('why-inatysco.items')"
      />
      <io-spacing large/>
      <io-spacing large/>
    </io-text-title>

    <io-create-connection
        :loading="loading"
        class="mx-auto"
        @create-room="createRoom"
        @connect-to-room="connectToRoom($event)"
    />
    <io-spacing large/>

    <transition name="fade-height">
      <io-text-label color="#f00" v-if="error">
        {{$t("connection.errors."+error)}}
      </io-text-label>
    </transition>

  </v-container>

  <v-spacer/>
  <v-container
      v-if="$services.user.isValid || changeNickNameActive"
      @click="changeNickNameActive=true"
      class="change-nickname">
    <io-user-name-input
        :active="changeNickNameActive"
        class="flex-grow-0 flex-shrink-1"
        v-model="$services.user.userName"
        @submit="changeNickNameActive=false"
    />
    <io-text-label class="mt-3">
      {{$t("start-connection.change-nickname")}}
    </io-text-label>
  </v-container>

</div>
</template>

<script>
import IoTextTitle from "@/components/atoms/io-text-title";
import IoMotionTextSlide from "@/components/atoms/motion-text/io-motion-text-slide";
import IoCreateConnection from "@/components/organisms/io-create-connection";
import IoSpacing from "@/components/atoms/io-spacing";
import IoUserNameInput from "@/components/organisms/io-user-name-input";
import IoTextLabel from "@/components/atoms/io-text-label";

export default {
  name: "home",
  components: {IoTextLabel, IoUserNameInput,  IoSpacing, IoCreateConnection, IoMotionTextSlide, IoTextTitle},
  data: function() {
    return {
      loading:false,
      error:"",
      roomId: "",
      changeNickNameActive:false
    }
  },
  methods: {
    createRoom() {
      let me=this;
      if(this.$services.user.isValid){
        me.loading=true;
        me.error="";
        this.$ui.connect(null,
            function(){
              me.$router.push('code');
            },
            function(error){
              me.loading=false;
              me.$ui.temporaryMessage=me.$t(error);
            },
        )
      }else{
        me.$ui.tempRoomId="create";
        me.$router.push('my-name-is');
      }
    },
    connectToRoom(roomId) {
      let me=this;
      if(me.$services.user.isValid){
        me.loading=true;
        me.error="";
        me.$ui.connect(roomId,
            function(){
              console.warn("success")
              me.$router.push('room');
            },
            function(error){
              me.loading=false;
              me.error=error;
            },
        )
      }else{
        me.$ui.tempRoomId=roomId;
        me.$router.push('my-name-is');
      }
    }
  }
}
</script>
<style lang="less">
.page-home{
  .change-nickname{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

</style>


<template>
  <div class="file-alt-line"
       @contextmenu="openContextMenu"
       @click="download"
       v-resize="onResize"
       :step="stepProxified"
       :class="{
          'upload':isUpload,
          'download':isDownload,
       }">
    <v-btn class="mt-n7 ml-n12 mr-2"
           icon
           @click.stop="openContextMenu"
    >
      <v-icon>io-ico-kebab</v-icon>
    </v-btn>
    <v-menu
        v-model="contextMenu.open"
        :position-x="contextMenu.x"
        :position-y="contextMenu.y"
        absolute
        offset-y
        style="max-width: 600px"
        rounded="xl"
    >
      <io-file-menu-card :file="file"/>
    </v-menu>

    <div class="line-log">
      <div class="line" ref="line">
          <div class="box">
              <io-dot
                  class="" style="margin-top: 14px"
                  :color="colorId"
                  size="12px"
              />
              <io-file-meta
                  class="mt-2"
                  :file="file"
              />
            <transition name="morph">
              <io-file-progress
                  class="mx-2 mt-4"
                  :percent="percentProxified"
                  :style="{width:`${progressWidth}`}"
                  :file="file"
                  v-if="isTransferring"
              />
            </transition>
            <transition name="morph">
              <io-file-dwd-btn
                  class="media mx-2"
                  v-if="isDownload && (isWaiting || isDownloaded)"
                  :file="file"
              />
            </transition>
            <transition name="morph">
              <io-square-avatar
                  class="media mx-2"
                  v-if="isUpload && (isWaiting || isDownloaded)">
                  <v-icon size="32px" color="var(--color-grey-light)">
                    {{isWaiting?'io-ico-download':'io-ico-folder'}}
                  </v-icon>
              </io-square-avatar>
            </transition>
            <transition name="morph">
              <io-square-avatar
                  class="media mx-2"
                  v-if="isEncoding || isDecoding">
                <io-text-code style="font-size: 20px;" color="var(--color-grey-light)">
                  <io-motion-text-encoding
                      min-length="3"
                      max-length="3"
                  />
                </io-text-code>
              </io-square-avatar>
            </transition>
            </div>
      </div>
      <io-file-log
          class="logs"
          :file="file"
          :step-proxified="stepProxified"
      />
    </div>

  </div>
</template>

<script>
import FileAltMixin from "@/components/file-items/FileAltMixin";
import IoFileProgress from "@/components/file-items/io-file-progress";
import IoDot from "@/components/atoms/io-dot";
import IoFileLog from "@/components/file-items/io-file-log";
import IoFileDwdBtn from "@/components/file-items/io-file-dwd-btn";
import IoMotionTextEncoding from "@/components/atoms/motion-text/io-motion-text-encoding";
import IoTextCode from "@/components/atoms/io-text-code";
import IoFileMeta from "@/components/file-items/io-file-meta";
import IoSquareAvatar from "@/components/atoms/io-square-avatar";
import IoFileMenuCard from "@/components/file-items/io-file-menu-card";
import FileAlt from "@/services/FileAlt";
import {gsap} from "gsap";

export default {
  name: "file-alt-line",
  components: {
    IoFileMenuCard,
    IoSquareAvatar,
    IoFileMeta,
    IoTextCode,
    IoMotionTextEncoding,
    IoFileDwdBtn, IoFileLog, IoDot, IoFileProgress},
  mixins:[FileAltMixin],
  props:{
    file:{
      /**
       * {FileAlt}
       */
      type:Object
    }
  },
  data:function(){
    return{
      maxWidth:300,
      contextMenu:{
        open:false,
        x:0,
        y:0,
      },
      proxy:{
        step:FileAlt.STEP_ENCODING,
        progressPercent:0
      },
      STEP_INTS:{},
      STEP_INTS_REVERSE:{}
    }
  },
  mounted() {
    this.onResize();
    this.STEP_INTS[FileAlt.STEP_ENCODING]=1;
    this.STEP_INTS[FileAlt.STEP_TRANFERRING]=2;
    this.STEP_INTS[FileAlt.STEP_DECODING]=3;
    this.STEP_INTS[FileAlt.STEP_WAITING]=4;
    this.STEP_INTS[FileAlt.STEP_DOWNLOADED]=5;
    this.STEP_INTS_REVERSE['1']=FileAlt.STEP_ENCODING;
    this.STEP_INTS_REVERSE['2']=FileAlt.STEP_TRANFERRING;
    this.STEP_INTS_REVERSE['3']=FileAlt.STEP_DECODING;
    this.STEP_INTS_REVERSE['4']=FileAlt.STEP_WAITING;
    this.STEP_INTS_REVERSE['5']=FileAlt.STEP_DOWNLOADED;
    this.startProxy();
  },
  watch:{
    step(){
      if(this.step===FileAlt.STEP_ENCODING){
        this.startProxy();
      }
    }
  },
  computed:{
    stepProxified(){
      let stepInt=this.STEP_INTS[this.step];
      let strpProxyInt=this.STEP_INTS[this.proxy.step];
      let lowerStepIn=Math.min(stepInt,strpProxyInt);
      if(this.step===FileAlt.STEP_DOWNLOADED){
        if(strpProxyInt>=4){
          return this.step;
        }
      }
      return this.STEP_INTS_REVERSE[""+lowerStepIn];
    },
    percentProxified(){
      return Math.min(this.proxy.progressPercent,this.file.progressPercentage);
    },
    //steps
    isEncoding() {
      return this.stepProxified===FileAlt.STEP_ENCODING;
    },
    isTransferring(){
      return this.stepProxified===FileAlt.STEP_TRANFERRING;
    },
    isDecoding() {
      return this.stepProxified===FileAlt.STEP_DECODING;
    },
    isWaiting(){
      return this.stepProxified===FileAlt.STEP_WAITING;
    },
    isDownloaded(){
      return this.stepProxified===FileAlt.STEP_DOWNLOADED;
    },

    progressWidth(){
      if(!this.isTransferring){
        return 0;
      }
      return this.$utils.ratio(
          this.percentProxified,
          100,
          this.maxWidth,
          0,
          0
      )+"px"
    }
  },
  methods:{
    startProxy(){
      gsap.killTweensOf(this.proxy);
      let tl = gsap.timeline({repeat: 0});
      tl.set(this.proxy,{step:FileAlt.STEP_ENCODING});
      tl.set(this.proxy,{progressPercent:0});
      tl.set(this.proxy,{step:FileAlt.STEP_TRANFERRING},"+=2");
      tl.fromTo(this.proxy, {progressPercent:0},{
        ease:"none",
        progressPercent:100,
        duration:2,
        snap: "progressPercent"
      });
      tl.set(this.proxy,{step:FileAlt.STEP_DECODING});
      tl.set(this.proxy,{step:FileAlt.STEP_WAITING},"+=2");
    },
    onResize(){
      let w=this.$refs.line.offsetWidth;
      this.maxWidth=w-218; // line width less others elements width
    },
    openContextMenu(e){
      e.preventDefault()
      this.contextMenu.open = false
      this.contextMenu.x = e.clientX
      this.contextMenu.y = e.clientY
      this.$nextTick(() => {
        this.contextMenu.open = true
      })
    },
  }
}
</script>

<style lang="less">
@import "./file-alt-line-up-dwd.less";
.file-alt-line{
  display: flex;
  align-items: center;
  user-select: none;
  .line-log{
    flex-grow: 1;
    position: relative;
    min-width: 100%;
    .line{
      position: relative;
      .box{
        position: absolute;
        >*{
          //outline: 1px solid #eee;
        }
      }
    }
  }

  --radius:15px;
  --height:70px;

  .line,.box{
    border-radius: var(--radius);
  }
  .line{
    height: var(--height);
    .box{
      height: 100%;
      background-color: #fff;
      border: 2px solid var(--color-grey-medium);
      transition: all 1s;
    }
  }

  // line colors & shadows
  .line{
    background-color: transparent;
    transition: all 1s;
  }
  &[step='TRANSFERRING']{
    .line{
      box-shadow: inset  2px  2px 10px rgba(0, 0, 0, 0.1);
      background-color: var(--color-grey-ultra-light);
      overflow: hidden;
    }
    .box{
      box-shadow:  2px  2px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .box{
    display: flex;
    padding: 5px 10px;
    align-items: start;
  }

  .logs{
    margin-top: 15px;
    width: 100%;
  }

  &[step='DECODING'],
  &[step='ENCODING'],
  &[step='TRANSFERRING']
  {
    .box {
      cursor: progress;
    }
  }

  // show / hide elements in the box
  .morph-enter-active, .morph-leave-active {
    transition:all 0.5s;
  }
  .morph-enter, .morph-leave-to /* .morph-leave-active below version 2.1.8 */ {
    filter: hue-rotate(180deg);
    //transform: translateX(-20px);
    opacity: 0;
    width: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }



}
</style>

const filesize = require('filesize');
export default class FileUtils {

    constructor() {


        this.extMimes = require("./mimes-list.json");
        this.familyExtensions=require("./file-families-extensions.json");
        this.familyMimePrecise = require("./file-families-mime-precise.json");
        this.familyMimeGeneral = require("./file-families-mime-general.json");

        /**
         * @typedef MimeExt A simple mime / extension association
         * @property {string} ext
         * @property {string} mime
         */

        /**
         * A list of Mime / extension associations
         * @type {MimeExt[] }
         */
        this.mimes=[];
        Object.keys(this.extMimes).forEach(key => {
            this.mimes.push({
                ext:key,
                mime:this.extMimes[key]
            })
        });
        this.mimes=this.mimes.sort( function(a,b){
            return a.mime<b.mime?-1:1;
        });
    }

    /**
     * According extension and/or mimetype will return a general file family
     * @param {String} mime exemple: image/jpeg
     * @param {String} extension exemple: jpg
     * @return {string} One of the FileUtils.FAMILY_something possibility
     */
    fileFamily(mime,extension){
        extension=extension.toLowerCase();
        let mimeSplit=mime.toLowerCase().split("/");
        let m1=mimeSplit[0];
        let m2=mimeSplit.length>1?mimeSplit[1]:"";

        // 1 - by extension
        for(let fam of FileUtils.FAMILY_ALL){
            if(this.familyExtensions[fam].indexOf(extension)>-1){
                return fam;
            }
        }
        // 2 - then... by mime precise
        for(let fam of FileUtils.FAMILY_ALL){
            for(let ext of this.familyMimePrecise[fam]){
                if(m2.indexOf(ext)>-1){
                    return fam;
                }
            }
        }
        // 3 - last chance... by mime general
        for(let fam of FileUtils.FAMILY_ALL){
            for(let ext of this.familyMimeGeneral[fam]){
                if(m1.indexOf(ext)>-1){
                    return fam;
                }
            }
        }
        return "";
    }





    /**
     * Force a file download
     * @param {File} blob
     * @param {string} filename
     */
    download(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 2000)
        }
    }

    /**
     * get a human readable file size string
     * @param {Number} bytes
     * @param {Number} decimals
     * @return {string}
     */
    humanFileSize(bytes, decimals = 0) {
        return filesize(bytes, {round: decimals});
    }


}

FileUtils.FAMILY_CODE="code";
FileUtils.FAMILY_EXE="exe";
FileUtils.FAMILY_DOC="doc";
FileUtils.FAMILY_EXCEL="excel";
FileUtils.FAMILY_ARCHIVE="archive";
FileUtils.FAMILY_IMAGE="image";
FileUtils.FAMILY_VIDEO="video";
FileUtils.FAMILY_AUDIO="audio";

FileUtils.FAMILY_ALL=[
    FileUtils.FAMILY_CODE,
    FileUtils.FAMILY_EXE,
    FileUtils.FAMILY_DOC,
    FileUtils.FAMILY_EXCEL,
    FileUtils.FAMILY_ARCHIVE,
    FileUtils.FAMILY_IMAGE,
    FileUtils.FAMILY_VIDEO,
    FileUtils.FAMILY_AUDIO,
];


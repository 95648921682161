<template>
<v-chip class="io-chip-player"
        :class="{
              'is-partner':isPartner,
              'is-user':isUser,
        }"
        :title="userName"
        :ripple="false"
        large color="white">
  <template v-if="isPartner">
    <icon-user :color="color" class="mr-2 ml-n2" size="42px" />
    <io-text-label>{{userName}}</io-text-label>
  </template>
  <template v-else>
    <io-text-label>{{userName}}</io-text-label>
    <icon-user :color="color" class="ml-2 mr-n2" size="42px"/>
  </template>


</v-chip>
</template>

<script>
import IoTextLabel from "@/components/atoms/io-text-label";
import IconUser from "@/components/atoms/icons/icon-user";
export default {
  name: "io-chip-player",
  components: {IconUser, IoTextLabel},
  props:{
    userName:{
      type:String
    },
    isPartner:{
      type:Boolean,
      default:false
    },
    isConnected:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    isUser(){
      return !this.isPartner;
    },
    color(){
      if(!this.isConnected){
        return "var(--color-grey-warm)";
      }
      return this.isPartner?"var(--color-player2)":"var(--color-player1)"
    }
  }
}
</script>

<style lang="less">
.io-chip-player{
  pointer-events: auto;
  user-select: none;
  height: 50px !important;
  //min-width: 150px;
  box-shadow: var(--io-elevation-helicopter);
  justify-content: flex-end;
  //grrr sometime vuetify drive me insane...
  &:hover{
    background: #fff !important;
    &:before{
      display: none;
    }
  }

  &.is-user{
    padding-left: 20px !important;
  }
  &.is-partner{
    justify-content: flex-start;
    padding-right: 20px !important;
  }
  .io-text-label{
    --io-text-label-size: 15px;
  }
  .icon-user{
    --s:52px;
    width: var(--s);
    height: var(--s);
    flex-shrink: 0;
  }
  .io-text-label{
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<template>
<div class="io-tab-label-room px-5">
  <io-dot :color="connected?'#ffffff':'#ff0000'"
          class="mr-5 io-motion-blink"
          />
  <io-text-code
      color="#fff"
      @click.native="copyToClipBoard"
      v-ripple
  >
    <io-motion-text-encoding :text="roomName"/>
  </io-text-code>
  <v-spacer/>
  <v-icon color="#fff"
          v-if="!this.connected"
          class="io-motion-blink hard"
  >io-ico-disconnect</v-icon>
  <v-icon color="#fff" @click="$ui.closeConnection()">io-ico-close</v-icon>
</div>
</template>

<script>
import IoTextCode from "@/components/atoms/io-text-code";
import IoDot from "@/components/atoms/io-dot";
import IoMotionTextEncoding from "@/components/atoms/motion-text/io-motion-text-encoding";
import IoRoomMixin from "@/components/mixins/IoRoomMixin";

export default {
  name: "io-tab-label-room",
  components: {IoMotionTextEncoding, IoDot, IoTextCode},
  mixins:[IoRoomMixin],
  methods: {
    copyToClipBoard(){
      this.$ui.copyToClipboard(this.$services.rtcConnection.roomId,{format:'text/plain'});
      this.$ui.temporaryMessage=this.$t('alerts.code-copied');
    }
  }
}
</script>

<style lang="less">
.io-tab-label-room{
  height: 64px;
  background: linear-gradient(
      90deg,
      var(--color-player1) 0%,
      var(--color-player2) 100%
  );
  color: #fff;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 300px;
  @media(min-width: @screen-sm){
    width: 400px;
  }
  max-width: 85%;
  .io-text-code{
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }

}
</style>
<template>
<div class="io-motion-text-slide">
  <!-- to get one line space, cause everything is absolute positioned here -->
  <div class="placeholder">{{placeholder}}</div>
  <div class="list" ref="list">
    <!-- list twice to get the infinite scrolling effect -->
    <template v-for="(ab) of ['a','b']" >
      <div v-for="(item,i) of items"
           :key="ab+i">
        {{item}}
      </div>
  </template>
  </div>
</div>
</template>

<script>
import {gsap} from "gsap"
export default {
  name: "io-motion-text-slide",
  data(){
    return{
      /**
       * The timeline
       * {gsap.core.Timeline|null}
       */
      tl:null
    }
  },
  props: {
    /**
     * {string[]} here come the texts line list
     */
    items:{
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.boot();
  },
  beforeDestroy() {
    this.kill();
  },
  computed:{
    placeholder(){
      return this.items.length > 0 ? this.items[0] : ""
    }
  },
  methods:{
    /**
     * destroy loop
     */
    kill(){
      if(this.tl){
        this.tl.kill();
      }
    },
    /**
     * (reset) Build & start motion
     */
    boot(){
      this.kill();
      /**
       *
       * @type {gsap.core.Timeline}
       */
      let tl = this.tl = gsap.timeline({repeat: -1, repeatDelay: 0});
      let list=this.$refs.list;
      let howMany=this.items.length;
      gsap.set(list,{y:0});
      //will move by percentage (responsive will be easier)
      let unit=100/(this.items.length*2);
      for(let i = 0; i <= howMany ; i++ ){
        let y=parseFloat( String(i*-unit) ).toPrecision(5)+'%';
        tl.to(list,{y:y,duration:0.5, ease: "back.in(1.3)"},">1")
      }
      //back to first line
      tl.set(list,{y:0},">");
    }
  }
}
</script>

<style lang="less">
.io-motion-text-slide{
  //some extra space to prevent typography baseline stuff
  clip-path: polygon(0 20%, 100% 20%, 100% 120%, 0% 120%);
  position: relative;
  user-select: none;
  >.list{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  >.placeholder{
    visibility: hidden;
    user-select: none;
  }
}
</style>
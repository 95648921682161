import { render, staticRenderFns } from "./io-text-title-2.vue?vue&type=template&id=02d4414a&"
import script from "./io-text-title-2.vue?vue&type=script&lang=js&"
export * from "./io-text-title-2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
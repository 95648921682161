import FileAlt from "@/services/FileAlt";

export default {
    data:function(){
      return{}
    },
    props: {
        file: {
            type: FileAlt,
            validator:function(obj){
                return typeof obj.fairswapFileSender !== "undefined"
            }
        }
    },
    computed: {

        //status / step
        status() {
            return this.file.status;
        },
        step(){
            return this.file.step;
        },

        //global states
        isPaused() {
            return this.status === FileAlt.STATUS_PAUSED;
        },
        isCanceled() {
            return this.status === FileAlt.STATUS_CANCELED;
        },
        isError() {
            return this.status === FileAlt.STATUS_ERROR;
        },

        //upload / download
        isDownload() {
            return !this.file.isUpload;
        },
        isUpload() {
            return this.file.isUpload;
        },

        //steps
        isEncoding() {
            return this.step===FileAlt.STEP_ENCODING;
        },
        isTransferring(){
            return this.step===FileAlt.STEP_TRANFERRING;
        },
        isDecoding() {
            return this.step===FileAlt.STEP_DECODING;
        },
        isWaiting(){
            return this.step===FileAlt.STEP_WAITING;
        },
        isDownloaded(){
            return this.step===FileAlt.STEP_DOWNLOADED;
        },

        //colors
        colorMime() {
            return this.$utils.colors.mimeColor(this.type, this.extension)
        },
        colorSize() {
            return this.$utils.colors.fileSizeColor(this.size)
        },
        colorId() {
            return this.$utils.colors.hashColor(this.id)
        },
        colorIdOpacity() {
            return this.colorId + "00";
        },

        //meta
        id() {
            return this.file.meta.id;
        },
        type() {
            return this.file.meta.type;
        },
        extension() {
            return this.file.meta.extension;
        },
        size() {
            return this.file.meta.size;
        },
        name() {
            return this.file.meta.name;
        }
    },
    methods:{
        download(){
            if(this.isDownload){
                if(this.isDownloaded || this.isWaiting){
                    if(this.$ui.fileTransferService){
                        this.$ui.fileTransferService.downloadFile(this.file);
                    }else{
                        console.warn("download aborted no fileTransferService")
                    }
                }else{
                    console.warn("download aborted step is "+this.step)
                }
            }else{
                console.warn("download aborted it is an upload")
            }

        }
    }
}

<template>
<h2 class="io-text-title2"  :style="{color:textColor}">
  <slot></slot>
</h2>
</template>

<script>
import IoTextMixin from "@/components/atoms/IoTextMixin";
export default {
  name: "io-text-title2",
  mixins:[IoTextMixin],
  props:{
    color:{
      type:String,
      default:"title"
    }
  }
}
</script>

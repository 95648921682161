<template>
<io-card class="io-chat-window">
  <template slot="header">
    <div>
      <div class="text-no-wrap">{{partnerName}} / {{userName}}</div>
    </div>
      <v-spacer/>
      <v-icon @click="$ui.chatOpen=false">io-ico-close</v-icon>
  </template>

  <div>
    <slot/>
    <div ref="bottom"/>
  </div>

  <template slot="actions">
    <v-form @submit.prevent="submit">
      <v-text-field
          ref="chatInput"
          v-model="message"
          rounded filled hide-details
          :placeholder="$t('chat.write-message')">
        <template slot="append">
          <v-icon @click="submit">io-ico-send</v-icon>
        </template>
      </v-text-field>
    </v-form>

  </template>
</io-card>
</template>

<script>
import IoCard from "@/components/atoms/io-card";
import IoRoomMixin from "@/components/mixins/IoRoomMixin";
export default {
  name: "io-chat-window",
  components: {IoCard},
  mixins:[IoRoomMixin],
  props:["toggle","lastMessage"],
  data(){
    return{
      message:""
    }
  },
  mounted() {
    this.$nextTick(function(){
      let me=this;
      setTimeout(function(){
        me.scrollBottom();
        me.$refs.chatInput.$refs.input.focus()
      },100);

    })
  },
  watch:{
    lastMessage(){
      this.scrollBottom();
    }
  },
  methods:{
    submit(){
      if(this.message !== ""){
        this.$emit("submit",this.message);
        this.message="";
      }
    },
    scrollBottom(){
      let me=this;
      setTimeout(function(){
        me.$refs.bottom.scrollIntoView()
      },100)

    }
  }
}
</script>

<style lang="less">
.io-chat-window{

  --width:calc(100vw - 20px);
  width: var(--width);
  .content{
    min-height: 150px;
    height: calc(100vh - 210px);
  }
  @media(min-width: @screen-md){
    --width: 350px;
    .content{
      min-height: 200px;
      max-height: calc(400px);
    }
  }
  padding: 0;
  .header{
    background-color: var(--color-grey-ultra-light);
    color:#000;
    width: 100%;
    height: 65px;
    display: flex;
    padding: 20px;
    .text-no-wrap{
      display: block;
      width: calc(var(--width) - 70px);
      text-overflow: ellipsis;
      overflow: hidden;
    }

  }
  .content{
    outline-offset: -2px;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  .actions{
    margin: 0;
    padding: 10px 15px;
    form{
      width: 100%;
    }
  }

  margin: 20px;
  position: relative;
  border-radius: 30px;

}
</style>
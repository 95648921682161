<template>
  <h1 class="io-text-code-lg"  :style="{color:textColor}">
    <slot/>
  </h1>

</template>

<script>
import IoTextMixin from "@/components/atoms/IoTextMixin";
export default {
  name: "io-text-code-lg",
  mixins:[IoTextMixin],
  props:{
    color:{
      type:String,
      default:"title"
    }
  }
}
</script>

<style scoped>

</style>
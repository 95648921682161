<template>
<div>


  <h3 class="text-h3 my-5">Pour dire que c'est sécurisé, cripté, hashé etc...</h3>
  <sg-divider>io-motion-text-encoding</sg-divider>
  <io-text-code-lg>
    <io-motion-text-encoding/>
  </io-text-code-lg>
  <io-text-code-lg>
    <io-motion-text-encoding
        possible-chars="ABab"
        min-length="4" max-length="8"/>
  </io-text-code-lg>
  <io-text-code-lg>
    <io-motion-text-encoding
        possible-chars="1234567890"
        min-length="4" max-length="4"/>
  </io-text-code-lg>
  <io-text-code-lg>
    <io-motion-text-encoding
        :text="randomName"
        min-length="5"
        max-length="10"/>
  </io-text-code-lg>

  <io-text-log>
    <io-motion-text-encoding
        :text="randomName"
        min-length="5"
        max-length="10"/>
  </io-text-log>

  <io-text-title2>
    <io-motion-text-encoding
        :text="randomName"
        min-length="5"
        max-length="10"/>
  </io-text-title2>

  <h3 class="text-h3 my-5">Pour expliquer...</h3>
  <sg-divider>io-motion-text-slide</sg-divider>

  <io-text-title>
    <div>Inatysco permet d'échanger des fichiers</div>
    <io-motion-text-slide
        :items="
          [
              'en toute sécurité',
              'en toute simplicité',
              'sans intermédiaire',
              'gratuitement',
              'tout simplement',
              'avec vos amis',
              'avec vos collègues',
          ]
    " />
  </io-text-title>
  <io-spacing/>
  <io-text-code-lg>
    <div>Inatysco permet d'échanger des fichiers</div>

    <io-motion-text-slide
        :items="
          [
              'en toute sécurité',
              'en toute simplicité',
              'sans intermédiaire',
              'gratuitement',
              'tout simplement',
              'avec vos amis',
          ]
    " />

  </io-text-code-lg>
  <io-spacing/>
  <io-text-title2>
    <div>Inatysco permet d'échanger des fichiers</div>
    <io-motion-text-slide
        :items="
          [
              'en toute sécurité',
              'en toute simplicité',
              'sans intermédiaire',
          ]
    " />
  </io-text-title2>


  <h3 class="text-h3 my-5">Pour inciter à l'action...</h3>
  <code>TODO animation texte fade...</code>
</div>
</template>

<script>
import IoMotionTextSlide from "@/components/atoms/motion-text/io-motion-text-slide";
import IoTextTitle from "@/components/atoms/io-text-title";
import SgDivider from "@/style-guide/sg-divider";
import IoTextCodeLg from "@/components/atoms/io-text-code-lg";
import IoSpacing from "@/components/atoms/io-spacing";
import IoTextTitle2 from "@/components/atoms/io-text-title-2";
import IoMotionTextEncoding from "@/components/atoms/motion-text/io-motion-text-encoding";
import IoTextLog from "@/components/atoms/io-text-log";

export default {
  name: "sg-motion-text",
  components: {
    IoTextLog,
    IoMotionTextEncoding, IoTextTitle2, IoSpacing, IoTextCodeLg, SgDivider, IoTextTitle, IoMotionTextSlide},
  data(){
    return{
      randomName:"Hello",
      loop:null,
    }
  },
  mounted() {
    let me=this;
    this.loop=setInterval(
        function(){
          me.randomName=this.$services.user.getRandomName();
        },6000
    )
  },
  beforeDestroy() {
    if(this.loop){
      clearInterval(this.loop);
    }
  }

}
</script>

<style lang="less">

</style>

import Home from "../pages/home";
import ConnectionCode from "../pages/connection-code";
import Room from "../pages/room";
import MyNameIs from "../pages/my-name-is";
import StyleGuide from "../pages/style-guide";
import VueRouter from "vue-router";
const router = new VueRouter({
    mode:"hash",
    routes: [
        {
            name:"home",
            path: '/',
            component: Home,
            meta:{
            }
        },
        {
            name:"code",
            path: '/code',
            component: ConnectionCode,
            meta:{
            }
        },
        {
            name:"myNameIs",
            path: '/my-name-is',
            component: MyNameIs,
            meta:{
            }
        },
        {
            name:"room",
            path: '/room',
            component: Room,
            meta:{
            }
        },
        {
            name:"style-guide",
            path: '/style-guide',
            component: StyleGuide,
            meta:{
            }
        }
    ]
})
window.$router=router;
export default router;

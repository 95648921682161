<template>
<div class="io-icon-elevate">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "io-icon-elevate"
}
</script>

<style lang="less">
.io-icon-elevate{
  box-shadow: var(--io-elevation-helicopter);
  background-color: #fff;
  --size:40px;
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.6);
  transform-origin: left center;
  margin-right: 5px;
  @media(min-width: @screen-md){
    transform: scale(1);
    margin-right: 15px;
  }
}

</style>
import UserNameService from "./UserNameService";
import {RtcConnectionService} from "@inatysco/webrtc-connection";


export default class Services {
    constructor() {
        this.user = new UserNameService();
        this.rtcConnection = new RtcConnectionService(this.user.userName);
    }
}

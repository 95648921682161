<template>
<div class="io-chat-line"
    :class="[
      isRecipient?'is-recipient':'is-user'
    ]">
  <div class="bubble"><slot/></div>
  <io-text-log>{{when}}</io-text-log>
</div>
</template>

<script>
import IoTextLog from "@/components/atoms/io-text-log";
export default {
  name: "io-chat-line",
  components: {IoTextLog},
  props:{
    isRecipient:{
      type:Boolean,
      default:false
    },
    date:{
      type:Date,
    }
  },
  computed:{
    when(){
      return this.$utils.date.timeHHmm(this.date);
    }
  }
}
</script>

<style lang="less">
.io-chat-line{

  //entrance motion
  animation: up 0.5s ;
  &.is-recipient {
    transform-origin: bottom left;
  }
  &.is-user{
    transform-origin: bottom right;
  }

 .bubble{
   display: inline-block;
   max-width: 100%;
   padding: 8px 20px;
   border-radius: 30px;
   overflow-wrap: break-word;
   .io-text-regular{
     line-height: 1;
   }
 }
  .io-text-log{
    margin: 8px 4px 15px 4px;
    opacity: 0.5;
  }
  &.is-user{
    text-align: right;
    .bubble{
      color: #fff;
      background-color: var(--color-player1);
      border-bottom-right-radius: 0;
    }
  }
  &.is-recipient{
    text-align: left;
    .bubble{
      color: #000;
      background-color: #ECEEF5;
      border-bottom-left-radius: 0;
    }

  }
}
</style>
<template>
<div class="io-disabler">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "io-disabler"
}
</script>

<style lang="less">
.io-disabler{
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(3px) saturate(0.1);
  padding: 20px;
}
</style>